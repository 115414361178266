const appStyle = (theme) => ({
  root: {
    marginTop: 30,
    height: "100%",
    overFlow: "hidden",
    color: "black",
    textAlign: "justify",
    letterSpacing: "2px",
  },
  cardHeaderModal: {
    boxShadow: `-5px -10px 1px grey`,
    background: `#d32f2f`,
  },
  cardTitleModal: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textModal: {
    textAlign: "justify",
  },
  btn: {
    background: `#d32f2f`,
    color: "white",
  },
  menu: {
    flexGrow: 1,
    display: "flex",
    height: "500px",
    color: "black",
  },
  content: {
    flexGrow: 1,
    display: "flex",
  },
  logo: {
    position: "fixed",
    maxWidth: 300,
    maxHeight: 300,
    margin: 30,
    top: 200,
  },
  logo_alt: {
    width: "100%",
    maxWidth: 300,
    maxHeight: 300,
    textAlign: "center",
    marginTop: 30,
  },
  text: {
    color: "#6C6C6C",
    textAlign: "justify",
    letterSpacing: "2px",
    fontFamily: "Montserrat",
    lineHeight: "2em",
    fontSize: 15,
    margin: 0,
    padding: 0,
  },
  contentText: {
    textAlign: "center",
    marginBottom: 70,
  },
  titulo: {
    color: "black",
    letterSpacing: "2px",
    fontFamily: "Montserrat",
    lineHeight: "2em",
    cursor: "pointer",
    "&:hover": {
      background: `rgba(0, 0, 0, 0.2)`,
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    padding: "5px",
    borderRadius: "30px",
    background: `#d32f2f`,
    letterSpacing: "2px",
    fontFamily: "Montserrat",
    lineHeight: "2em",
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      background: `rgba(0, 0, 0, 0.2)`,
      color: "white",
    },
  },
  appBar: {
    color: "black",
  },
  copyright: {
    fontFamily: "Montserrat",
    fontSize: "13px",
    marginTop: 5,
  },
  footerText: {
    fontFamily: "Montserrat",
    // margin: 5,
    fontSize: "70%",
    fontWeight: "bold",
    cursor: "pointer",
    borderRadius: 30,
    padding: 10,
    "&:hover": {
      background: `rgba(0, 0, 0, 0.5)`,
    },
  },
  appBarFooter: {
    top: "auto",
    bottom: 0,
    textAlign: "center",
    padding: "13px 0px 13px 0",
    backgroundColor: "#d32f2f",
    color: "white",
  },

  textFooter: {
    color: "white",
    margin: "0 auto",
    fontSize: 12,
    fontFamily: "Montserrat",
  },
  rootSearch: {
    padding: "0px 4px 0 30px",
    display: "flex",
    alignItems: "center",
    width: "90%",
    backgroundColor: "#EEEEEE",
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor: "white",
    paddingLeft: 30,
    borderColor: "#EEEEEE",
    borderRadius: 30,
  },

  rootStepper: {
    // width: '100%',
    backgroundColor: "Transparent",
    textAlign: "left",
  },

  buttonStepper: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonStepperColor: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: `#d32f2f`,
    color: "white",
  },
  actionsContainerStepper: {
    marginBottom: theme.spacing(2),
  },
  resetContainerStepper: {
    padding: theme.spacing(3),
    backgroundColor: "Transparent",
  },
});

export default appStyle;
