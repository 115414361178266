import React, { useState, useEffect } from "react";
import moment from 'moment'
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography, ListItem, List, ListItemSecondaryAction, ListItemText, ListItemAvatar, Checkbox, Avatar, Tooltip, IconButton, TextField, Table, TableBody, Grid, TableRow } from "@material-ui/core";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import FlagIcon from '@material-ui/icons/Flag';

import CreateIcon from '@material-ui/icons/Create';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Dialog from "../components/Dialog/Dialog.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardFooter from "../components/Card/CardFooter.js";
import ButtonFloating from "../components/CustomButtons/ButtonFloating.js";
import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesAdmin from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Navbar from "../components/Navbars/Navbar.js";
import InputFile from "../components/CustomInput/InputFile.js";
import Footer from "../components/Footer/Footer.js";

//Funtions
import { Empty, validateInputs, mostrarError, crear_form_data, formulario, consulta, Toast, colorDinamic, iconoDinamic, getError, API } from '../funciones/general';

const useStyles = makeStyles(styles);
const useStylesAdmin = makeStyles(stylesAdmin);

function ListService({ servicios, addServicePublicity, removeServicePublicity }) {
  const classes = useStyles();

  const handleToggle = (value, idPermiso) => () => {
    if (!idPermiso) addServicePublicity(value);
    else removeServicePublicity(idPermiso);
  };

  return (
    <List dense className={classes.rootList}>
      {servicios.map(({ id, nombre, permiso }) => {
        const labelId = `checkbox-list-primary-label-${id}`;
        return (
          <>
            <ListItem key={id} button className={classes.itemList}>
              <ListItemAvatar>
                <Avatar style={{ background: permiso ? '#009688' : '#b71c1c', color: 'white' }}>{nombre.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {nombre}
                    </Typography>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleToggle(id, permiso)}
                  checked={permiso ? true : false}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  );
}

function DetailPublicity({ data }) {
  const classes = useStyles();

  let {
    nombre,
    archivo,
    valorc,
    fecha_registro,
    usuario_registro,
    descripcion,
    estado
  } = data;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (

    <Table>
      <TableBody>
        <TableRow key={1}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Nombre</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{nombre}</Grid>
          </Grid>
        </TableRow>
        {valorc &&
          <TableRow key={2}>
            <Grid container className={classes.tableRow}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Link</Grid>
              <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{<span onClick={() => window.open(valorc)}>VER</span>}</Grid>
            </Grid>
          </TableRow>}
        <TableRow key={6}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Descripción</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{descripcion}</Grid>
          </Grid>
        </TableRow>
        {archivo &&
          <TableRow key={-16}>
            <Grid container className={classes.tableRow}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Imagen</Grid>
              <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}><span onClick={() => window.open(`${API}${archivo}`)}>VER</span></Grid>
            </Grid>
          </TableRow>}
        <TableRow key={3}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Estado Actual</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{estado == 1 ? 'Activo' : 'Inactivo'}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={4}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Registrado por</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{usuario_registro && usuario_registro.usuario}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={5}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Fecha Registro</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{fecha}</Grid>
          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  )
}

function AddService({ nombre, setNombre, setLink, link, setArchivo, archivo, descripcion, setDescripcion, errores }) {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setNombre(value)}
                value={nombre}
                error={getError('nombre', errores) && true}
                helperText={getError('nombre', errores)}
                label="Nombre"
                fullWidth={true}
                required
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setLink(value)}
                value={link}
                error={getError('link', errores) && true}
                helperText={getError('link', errores)}
                label="Link"
                fullWidth={true}
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <input
                style={{ display: 'none' }}
                accept="image/*"
                // ref={this.fileInput}
                type="file"
                id="contenido_publicidad"
                name="archivo"
                label='Adjuntar Imagen'
                onChange={({ target: { value } }) => setArchivo(value)}
              />
              <InputFile
                label='Adjuntar Imagen'
                id='contenido_publicidad'
                value={archivo}
                error={getError('archivo', errores) && true}
                helperText={getError('archivo', errores)}
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setDescripcion(value)}
                value={descripcion}
                label="Descripción"
                fullWidth={true}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}


function ShowData({ data, setDataSele, updateValueGenerica, setLink, setModalAdd, setNombre, setDescripcion, setTipo, setErrores, setModalDetail, setModalAddService, getServices }) {
  const classes = useStyles();

  const showDataUpdate = (e) => {
    setModalAdd(true);
    setDataSele(e);
    setNombre(e.nombre);
    setDescripcion(e.descripcion);
    setLink(e.valorc);
    setTipo('update');
    setErrores([]);
  }


  return (
    data.map(e => {
      let iconoD = iconoDinamic(parseInt(e.valorb));
      return (
        <GridItem
          xs={12}
          sm={6}
          md={3}
        >
          <Card className={classes.cardItem}
            onClick={() => {
              setDataSele(e);
              setModalDetail(true)
            }}
          >
            <CardHeader color={e.valora} stats icon>
              <CardIcon color={e.valora}>
                {iconoD.icon()}
              </CardIcon>
              <p className={classes.cardCategory}>
                {e.nombre.slice(0, 16).toUpperCase()}
                {e.nombre.length > 16 ? ' ...' : ''}
              </p>
              <h4 className={classes.cardTitle}>
                <small>
                  {e.descripcion.slice(0, 40)}
                  {e.descripcion.length > 40 ? ' ...' : ''}
                </small>
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats} style={{ textAlign: 'left' }}>
                <FlagIcon />
                {`Estado : ${e.estado == 1 ? 'Activo' : 'Inactivo'}`}<br />
                <DateRange />
                {`Creado el ${moment(e.fecha_registro).format('YYYY-MM-DD, h:mm a')}`}
              </div>
            </CardFooter>
          </Card>
          <div className={classes.stats}>
            {
              e.estado == 1
                ? <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#b71c1c" }} title="Desactivar" onClick={() => updateValueGenerica(e.id, { estado: 0 })}><IconButton className={classes.statsIcon}  ><VisibilityOffIcon fontSize="small" /> </IconButton></Tooltip>
                : <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#009688" }} title="Activar" onClick={() => updateValueGenerica(e.id, { estado: 1 })} ><IconButton className={classes.statsIcon}  ><VisibilityIcon fontSize="small" /> </IconButton></Tooltip>
            }
            <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#2196f3" }} title="Editar" onClick={() => showDataUpdate(e)} ><IconButton className={classes.statsIcon}><CreateIcon fontSize="small" /> </IconButton></Tooltip>
            <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#673ab7" }} title="Servicios" onClick={() => {
              setDataSele(e);
              getServices(e.id);
              setModalAddService(true);
            }} ><IconButton className={classes.statsIcon}><LibraryAddIcon fontSize="small" /> </IconButton></Tooltip>
          </div>
        </GridItem>
      )
    })
  )
}



export default function Publicity() {
  let currentUrl = window.location.pathname.split("/")[2];
  const mainPanel = React.createRef();
  const classes = useStylesAdmin();
  const [search, setSearch] = useState('');
  const [tipo, setTipo] = useState('add');
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalAddService, setModalAddService] = useState(false);
  const [modalDetailContent, setModalDetailContent] = useState(false);
  const [wait, setWait] = useState(true);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [link, setLink] = useState('');
  const [archivo, setArchivo] = useState('');
  const [data, setData] = useState([]);
  const [dataContent, setDataContent] = useState([]);
  const [dataSele, setDataSele] = useState({});
  const [errores, setErrores] = useState([]);
  const [dataService, setDataService] = useState([]);

  useEffect(() => {
    getPublicity();
  }, []);

  const addValueGenerica = async (data) => {
    let errores = await validateInputs([
      { value: nombre, name: 'nombre', err: { empty: true } },
      { value: archivo, name: 'archivo', err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      data = await crear_form_data(data);
      formulario("valores/crear", data, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            setNombre('');
            setDescripcion('');
            setArchivo('');
            setLink('');
            document.getElementById('contenido_publicidad').value = '';
            getPublicity(search);
            Toast.fire({ icon: 'success', title: resp.titulo });
          } else {
            let newError = errores;
            // newError.push({'llave' : 'nombre', 'mensaje' : 'Este campo es obligatorio'});
            setErrores(newError);
            Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
          }
        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  }

  const updateValueGenerica = async (id, data, tipoUp = 'update') => {
    let errores = await validateInputs([
      { value: nombre, name: 'nombre', err: { empty: true } },
    ]);
    if (errores.length == 0 || tipoUp != 'update_all') {
      setErrores([]);
      data = await crear_form_data(data);
      formulario(`valores/${id}`, data, "patch",
        (error, estado, resp) => {
          if (estado === 200) {
            setNombre('');
            setDescripcion('');
            setArchivo('');
            setLink('');
            document.getElementById('contenido_publicidad').value = '';
            setModalAdd(false);
            setTipo('add');
            getPublicity(search);
            Toast.fire({ icon: 'success', title: resp.titulo });
          } else Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });

        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  }


  const getPublicity = (search = '') => {
    setWait(true);
    consulta(`generica/${4}/valores?search=${search}`, null, null, (error, estado, resp) => {
      setWait(false);
      setData(resp);
    });
  };

  const getServices = (publicity) => {
    setWait(true);
    consulta(`valores/${publicity}/permisos?generica=1`, null, null, (error, estado, resp) => {
      setWait(false);
      setDataService(resp);
    });
  };

  const addServicePublicity = (principal, secundario) => {
    consulta(`permisos/crear`, { principal, secundario }, 'post',
      (error, estado, resp) => {
        if (estado === 200) {
          getServices(principal);
          Toast.fire({ icon: 'success', title: resp.titulo });
        } else Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
      });
  };

  const removeServicePublicity = (id, principal) => {
    consulta(`permisos/${id}`, { estado: 0 }, 'patch',
      (error, estado, resp) => {
        if (estado === 200) {
          getServices(principal);
          Toast.fire({ icon: 'success', title: resp.titulo });
        } else Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
      });
  };


  return (
    <div className={classes.wrapper}>
      <div ref={mainPanel}>
        <div>
          <Navbar
            text={currentUrl}
            callbackSearch={(data) => {
              setSearch(data);
              getPublicity(data);
            }}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <div>
                {
                  data.length === 0 && <Empty />
                }
                <GridContainer>
                  <ShowData
                    data={data}
                    setDataSele={setDataSele}
                    updateValueGenerica={updateValueGenerica}
                    setModalAdd={setModalAdd}
                    setNombre={setNombre}
                    setDescripcion={setDescripcion}
                    setTipo={setTipo}
                    setErrores={setErrores}
                    setModalDetail={setModalDetail}
                    setModalAddService={setModalAddService}
                    getServices={getServices}
                    setLink={setLink}
                  />
                </GridContainer>
                <ButtonFloating callback={() => setModalAdd(true)} />
                <Dialog
                  open={modalAdd}
                  setOpen={(modal) => {
                    if (tipo == 'update') {
                      setNombre('');
                      setDescripcion('');
                      setLink('');
                      setArchivo('');
                    }
                    setTipo('add');
                    setErrores([]);
                    setModalAdd(modal);
                  }}
                  title={`${tipo == 'add' ? 'Nueva' : 'Modificar'} Publicidad`}
                  subTitle='Complete los siguientes campos'
                  children={
                    <AddService
                      errores={errores}
                      descripcion={descripcion}
                      nombre={nombre}
                      setDescripcion={setDescripcion}
                      setNombre={setNombre}
                      setLink={setLink}
                      link={link}
                      setArchivo={setArchivo}
                      archivo={archivo}
                    />}
                  callback={
                    handleClose => {
                      let archivoPublicidad = document.getElementById('contenido_publicidad').files[0];
                      let valora = colorDinamic().color;
                      let valorb = parseInt(Math.random() * (10 - 0) + 0);
                      let valorc = link;
                      let dataAdd = { generica: 4, nombre, descripcion, valora, valorb, valorc, archivo: archivoPublicidad };
                      let dataUpd = { nombre, valorc, descripcion };
                      if (archivo) dataUpd.archivo = archivoPublicidad;
                      if (tipo == 'add') addValueGenerica(dataAdd, handleClose);
                      else updateValueGenerica(dataSele.id, dataUpd, 'update_all');
                      return false;
                    }}
                />

                <Dialog
                  nameBtn1=''
                  nameBtn2='CERRAR'
                  open={modalDetail}
                  setOpen={setModalDetail}
                  title={`Detalle Publicidad`}
                  subTitle={''}
                  children={dataSele ? <DetailPublicity data={dataSele} /> : <p></p>}
                // tasks={ <OpenServiceTasks /> } 
                />

                <Dialog
                  nameBtn1=''
                  nameBtn2='CERRAR'
                  open={modalAddService}
                  setOpen={setModalAddService}
                  title={`Asignar Servicio`}
                  subTitle={dataSele && dataSele.nombre}
                  children={<ListService servicios={dataService} addServicePublicity={(service) => addServicePublicity(dataSele.id, service)} removeServicePublicity={(id) => removeServicePublicity(id, dataSele.id)} />}
                // tasks={ <OpenServiceTasks /> } 
                />

              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
