import React, { useState, useEffect } from "react";
import moment from 'moment'
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import { Tooltip, IconButton, TextField, Table, TableBody, Grid, TableRow } from "@material-ui/core";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import CreateIcon from '@material-ui/icons/Create';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonIcon from '@material-ui/icons/Person';
import FlagIcon from '@material-ui/icons/Flag';
import PersonPinIcon from '@material-ui/icons/PersonPin';
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Dialog from "../components/Dialog/Dialog.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardFooter from "../components/Card/CardFooter.js";
import ButtonFloating from "../components/CustomButtons/ButtonFloating.js";
import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesAdmin from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
//Funtions
import { Empty, mostrarError, crear_form_data, formulario, consulta, Toast, validateInputs, getError } from '../funciones/general';

const useStyles = makeStyles(styles);
const useStylesAdmin = makeStyles(stylesAdmin);

function DetailUser({ data }) {
  const classes = useStyles();

  let {
    nombre,
    usuario,
    fecha_registro,
    correo,
    usuario_registro,
    estado
  } = data;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (

    <Table>
      <TableBody>
        <TableRow key={1}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Nombre</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{nombre}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={2}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Correo</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{correo}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={3}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Usuario</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{usuario}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={4}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Estado Actual</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{estado == 1 ? 'Activo' : 'Inactivo'}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={5}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Registrado por</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{usuario_registro && usuario_registro.usuario}</Grid>
          </Grid>
        </TableRow>
        <TableRow key={6}>
          <Grid container className={classes.tableRow}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowTitle}>Fecha Registro</Grid>
            <Grid align="left" item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.tableRowVal}>{fecha}</Grid>
          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  )
}

function ModalAddUser({ nombre, setNombre, usuario, setUsuario, correo, setCorreo, password, setpassword, errores }) {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setNombre(value)}
                value={nombre}
                error={getError('nombre', errores) && true}
                helperText={getError('nombre', errores)}
                label="Nombre"
                fullWidth={true}
                required
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setUsuario(value)}
                value={usuario}
                error={getError('usuario', errores) && true}
                helperText={getError('usuario', errores)}
                label="Usuario"
                fullWidth={true}
                required
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                onChange={({ target: { value } }) => setCorreo(value)}
                value={correo}
                error={getError('correo', errores) && true}
                helperText={getError('correo', errores)}
                label="Correo"
                fullWidth={true}
                required
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                type='password'
                onChange={({ target: { value } }) => setpassword(value)}
                value={password}
                error={getError('password', errores) && true}
                helperText={getError('password', errores)}
                label="Contraseña"
                fullWidth={true}
                required
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function ShowData({ data, setDataSele, updateUser, setModalAdd, setNombre, setUsuario, setTipo, setCorreo, setpassword, setModalDetail, setErrores }) {
  const classes = useStyles();

  const showDataUpdate = (data) => {
    let { nombre, correo, usuario } = data;
    setDataSele(data);
    setNombre(nombre);
    setUsuario(usuario);
    setCorreo(correo);
    setpassword('');
    setTipo('update');
    setErrores([]);
    setModalAdd(true);
  }


  return (
    data.map(e => {
      return (
        <GridItem xs={12} sm={6} md={3} >
          <Card className={classes.cardItem}
            onClick={() => {
              setDataSele(e);
              setModalDetail(true)
            }}
          >
            <CardHeader color={e.estado == 1 ? 'success' : 'danger'} stats icon>
              <CardIcon color={e.estado == 1 ? 'success' : 'danger'}>
                <PersonIcon />
              </CardIcon>
              <p className={classes.cardCategory}> {e.usuario}</p>
              <p className={classes.cardTitle}> {e.nombre}</p>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats} style={{ textAlign: 'left' }}>
                <FlagIcon />
                {`Estado : ${e.estado == 1 ? 'Activo' : 'Inactivo'}`}<br />
                <DateRange />
                {`Creado el ${moment(e.fecha_registro).format('YYYY-MM-DD, h:mm a')}`}
              </div>
            </CardFooter>
          </Card>
          <div className={classes.stats}>
            {
              e.estado == 1
                ? <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#b71c1c" }} title="Desactivar" onClick={() => updateUser(e.id, { estado: 0 })}><IconButton className={classes.statsIcon}  ><VisibilityOffIcon fontSize="small" /> </IconButton></Tooltip>
                : <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#009688" }} title="Activar" onClick={() => updateUser(e.id, { estado: 1 })} ><IconButton className={classes.statsIcon}  ><VisibilityIcon fontSize="small" /> </IconButton></Tooltip>
            }
            <Tooltip className={classes.statsTolltip} style={{ backgroundColor: "#2196f3" }} title="Editar" onClick={() => showDataUpdate(e)} >
              <IconButton className={classes.statsIcon}><CreateIcon fontSize="small" /> </IconButton></Tooltip>
          </div>
        </GridItem>
      )
    })
  )
}

export default function Users() {
  let currentUrl = window.location.pathname.split("/")[2];
  const mainPanel = React.createRef();
  const classes = useStylesAdmin();
  const [tipo, setTipo] = useState('add');
  const [search, setSearch] = useState('');
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [wait, setWait] = useState(true);
  const [nombre, setNombre] = useState('');
  const [usuario, setUsuario] = useState('');
  const [correo, setCorreo] = useState('');
  const [password, setpassword] = useState('');
  const [data, setData] = useState([]);
  const [dataSele, setDataSele] = useState({});
  const [errores, setErrores] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);


  const addUser = async (data) => {
    let errores = await validateInputs([
      { value: nombre, name: 'nombre', err: { empty: true } },
      { value: usuario, name: 'usuario', err: { empty: true } },
      { value: correo, name: 'correo', err: { empty: true } },
      { value: password, name: 'password', err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      consulta("usuarios/crear", data, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            setNombre('');
            setUsuario('');
            setCorreo('');
            setpassword('');
            getUsers(search);
            Toast.fire({ icon: 'success', title: resp.titulo });
          } else {
            Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
          }
        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  }


  const getUsers = (search = '') => {
    setWait(true);
    consulta(`usuarios?search=${search}`, null, null, (error, estado, resp) => {
      setWait(false);
      setData(resp);
    });
  };

  const updateUser = async (id, data, tipoUp = 'update') => {
    let errores = await validateInputs([
      { value: nombre, name: 'nombre', err: { empty: true } },
      { value: usuario, name: 'usuario', err: { empty: true } },
      { value: correo, name: 'correo', err: { empty: true } },
      { value: password, name: 'password', err: { empty: true } },
    ]);
    if (errores.length == 0 || tipoUp != 'update_all') {
      setErrores([]);
      data = await crear_form_data(data);
      formulario(`usuarios/${id}`, data, "patch",
        (error, estado, resp) => {
          if (estado === 200) {
            Toast.fire({ icon: 'success', title: resp.titulo });
            setNombre('');
            setUsuario('');
            setCorreo('');
            setpassword('');
            getUsers(search);
            setModalAdd(false);
          } else {
            Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
          }
        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  }


  return (
    <div className={classes.wrapper}>
      <div ref={mainPanel}>
        <div>
          <Navbar
            text={currentUrl}
            callbackSearch={(data) => {
              setSearch(data);
              getUsers(data);
            }}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <div>
                {
                  data.length === 0 && <Empty />
                }
                <GridContainer>
                  <ShowData
                    data={data}
                    setDataSele={setDataSele}
                    updateUser={updateUser}
                    setModalAdd={setModalAdd}
                    setNombre={setNombre}
                    setUsuario={setUsuario}
                    setTipo={setTipo}
                    setCorreo={setCorreo}
                    setpassword={setpassword}
                    setModalDetail={setModalDetail}
                    setErrores={setErrores}
                  />
                </GridContainer>
                <Dialog
                  open={modalAdd}
                  setOpen={setModalAdd}
                  title={`${tipo == 'add' ? 'Agregar' : 'Modificar'} Usuario`}
                  subTitle='Complete los siguientes campos'
                  children={
                    <ModalAddUser
                      errores={errores}
                      usuario={usuario}
                      nombre={nombre}
                      correo={correo}
                      password={password}
                      setUsuario={setUsuario}
                      setNombre={setNombre}
                      setCorreo={setCorreo}
                      setpassword={setpassword}
                    />
                  }
                  callback={
                    handleClose => {
                      let dataAdd = { nombre, usuario, correo, password };
                      let dataUpd = { nombre, usuario, correo };
                      if (password) dataUpd.password = password;
                      if (tipo == 'add') addUser(dataAdd, handleClose);
                      else updateUser(dataSele.id, dataUpd);
                      return false;
                    }}
                />
                <Dialog
                  nameBtn1=''
                  nameBtn2='CERRAR'
                  open={modalDetail}
                  setOpen={setModalDetail}
                  title={`Detalle Uusario`}
                  subTitle={''}
                  children={dataSele ? <DetailUser data={dataSele} /> : <p></p>}
                // tasks={ <OpenServiceTasks /> } 
                />

                <ButtonFloating callback={() => {
                  if (tipo != 'add') {
                    setNombre('');
                    setUsuario('');
                    setCorreo('');
                    setpassword('');
                  }
                  setErrores([]);
                  setModalAdd(true);
                  setTipo('add');
                }} />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
