import React from 'react';
import { Button, Typography, TextField, Box, Container, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../assets/img/shield.png";
import CardHeader from "../components/Card/CardHeader";
import { Empty, mostrarError, crear_form_data, formulario, consulta, Toast } from '../funciones/general';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  paper: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(13),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: 150,
    position: 'fixed',
    zIndex: 1000,
    top: 60
  },
  logo_cuc: {
    minWidth: '250px',
    width: "25%",
  },
  div_logo_cuc: {

    textAlign: "center"
  },
  wrapper: {
    margin: '1px 0px 1px 0px ',
    position: 'relative',
  },
  buttonProgress: {
    color: '#3f51b5',
    position: 'absolute',
    top: '50%',
    left: '3%',
    marginTop: -12,

  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: 'center',
  }
}));


export default function Login() {
  const classes = useStyles();
  const [cargando, setCargando] = React.useState(false);
  const [mensaje, setMensaje] = React.useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    let correo = document.getElementById('correo').value
    let password = document.getElementById('contrasena').value
    setCargando(true);
    consulta("validar_credenciales", { correo, password }, "post",
      (error, estado, resp) => {
        if (estado === 200) {
          localStorage.setItem('token', resp.token);
          window.location.href = "./servicios";
          Toast.fire({ icon: 'success', title: "Bienvenido..." });
        } else {
          Toast.fire({ icon: 'info', title: resp.titulo ? resp.titulo : mostrarError(resp) });
        }
        setCargando(false);
        return true;
      }, false
    );
    return true;
  }

  return (
    <Container component="main" maxWidth="xs" className='fondo_app' style={{ padding: 0 }}>
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <CardHeader color="primary" style={{ width: "100%" }}>
          <h4 className={classes.cardTitleWhite}>PANEL ADMINISTRADOR</h4>
        </CardHeader>
        <img src={logo} alt="Logo logo" className={classes.logo} />
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="correo"
            label="Correo"
            name="correo"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="contrasena"
            label="Contraseña"
            type="password"
            id="contrasena"
          />
          <div className={classes.wrapper}>
            <Button
              type='submit'
              variant="contained"
              color='primary'
              // style={{ backgroundColor: '#3f51b5', color: 'white' }}
              disabled={cargando}
              fullWidth={true}
              onClick={() => { }}
            >
              INGRESAR
              </Button>
            {cargando && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>

        </form>
      </div>

      <Typography variant="subtitle1" color="inherit" align="center">
        {mensaje}
      </Typography>
      <Box mt={3}>
      </Box>
    </Container>
  );
}
