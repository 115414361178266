import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import loaderBlack from "../assets/img/loaderBlack.gif";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  AppBar,
  DialogContent,
  Fab,
} from "@material-ui/core";
import VideoCall from "@material-ui/icons/VideoCall";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import Dialog from "../components/Dialog/Dialog.js";

//Funtions
import {
  API,
  getService,
  getError,
  validateInputs,
  Toast,
  consulta,
  mostrarError,
  showMedia,
} from "../funciones/general";

function AddPhone({
  phone,
  setPhone,
  errores,
  enviado,
  integracion,
  data = { data },
}) {
  return (
    <div>
      <Grid container>
        {enviado ? (
          <Grid item xs={12} sm={12} md={12}>
            <p style={{ textAlign: "center", fontSize: 15 }}>
              {integracion == "SPEEDY" || integracion == "" ? (
                <span>
                  <br />
                  En breve recibirás un mensaje en tu pantalla. Haz clic en{" "}
                  <b>ACEPTAR</b> para continuar con tu solicitud de inscripción
                  a {data.nombre}, con los mejores contenidos y noticias. No
                  olvides recargar tu línea para seguir disfrutando de nuestros
                  servicios. <br />
                </span>
              ) : (
                <span>
                  <br />
                  En breve recibirás un mensaje en tu pantalla. Recuerda hacer
                  clic en el link del mensaje de <b>BIENVENIDA</b> para que
                  puedas disfrutar de todos los contenidos. No olvides recargar
                  tu teléfono.
                  <br />
                </span>
              )}
            </p>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12}>
            <p style={{ textAlign: "center", fontSize: 15 }}>
              {" "}
              Para suscribirte a este sitio web por favor ingresa tu número de
              teléfono aquí. Te enviaremos un SMS de Bienvenida con toda la
              información.
            </p>
            <TextField
              onChange={({ target: { value } }) => setPhone(value)}
              value={phone}
              error={getError("phone", errores) && true}
              helperText={getError("phone", errores)}
              label="Ingresa tu número de teléfono"
              fullWidth={true}
              required
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

function Header({ service }) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ padding: "50px 0 30px 0" }}
    >
      <img
        src={`${API}${service.archivo4}`}
        alt=""
        style={{ maxWidth: 300, maxHeight: 300 }}
      />
    </Grid>
  );
}
function VideoFooter({ service, setModalVideo, modalVideo }) {
  const { archivo9 } = service;
  return !!archivo9 ? (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modalVideo}
        nameBtn2="Cerrar"
        setOpen={() => setModalVideo(false)}
        onClose={() => setModalVideo(false)}
        callback={() => setModalVideo(false)}
        title="¡Bienvenido!"
        subTitle="Estás a un paso de acceder al mejor contenido."
      >
        <DialogContent className="scroll" style={{ padding: 0 }}>
          <div>
            {showMedia("", "video", archivo9, true, {
              width: "100%",
              height: "100%",
            })}
          </div>
        </DialogContent>
      </Dialog>
      <Fab
        elevation={3}
        onClick={() => setModalVideo(true)}
        style={{
          zIndex: 1221,
          position: "fixed",
          bottom: "12%",
          right: 10,
          backgroundColor: "#b71c1c",
          color: "white",
        }}
      >
        <VideoCall />
      </Fab>
    </>
  ) : (
    <></>
  );
}

function Wait() {
  return (
    <div>
      <div
        style={{ padding: "200px 0 0 0", width: "100%", textAlign: "center" }}
      >
        <img src={loaderBlack} width="200" alt="" />
        <p>Cargando...</p>
      </div>
    </div>
  );
}

export default function Inscripcion() {
  const params = useParams();
  const [wait, setWait] = useState(true);
  const [modalSuscripcion, setModalSuscripcion] = useState(false);
  const [phone, setPhone] = useState("");
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [automatic, setAutomatic] = useState(true);
  const [integracion, setIntegracion] = useState("");
  const [errores, setErrores] = useState([]);
  const [pk, setPk] = useState(params.id);
  const [idScenSend, setIdScenSend] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let idScen = urlParams.get("idScen");
    setWait(true);
    let { success, data, celular, integracion } = await getService(pk);
    if (success) {
      setIntegracion(integracion);
      setData(data);
      setModalVideo(!!data?.archivo9);
      setPhone(celular);
      setIdScenSend(idScen ? idScen : data.valorn);
      if (celular && (integracion == "SPEEDY" || integracion == "")) {
        iniciarSpeed(celular, () => {}, true, idScen ? idScen : data.valorn);
        setAutomatic(true);
      } else if (celular && integracion == "ZED") {
        iniciarZed(celular, () => {}, true);
        setAutomatic(true);
      } else setAutomatic(false);
    } else setError(true);
    setWait(false);
  };

  const sendDataUrl = (msisdn, click_id) => {
    const { valorv: idCampana, valorw: endPoint, valorc: idProduct } = data;

    if (endPoint) {
      fetch(
        `${endPoint}?clickid=${click_id}&campaignid=${idCampana}&pub=web&productid=${idProduct}&partner=CLARO-COLOMBIA&msisdn=${msisdn}`
      )
        .then((response) => response.json())
        .then((data) => console.log(data));
    }
  };

  const iniciarSpeed = async (
    phone,
    callback = () => {},
    automatic = false,
    idS = ""
  ) => {
    let errores = automatic
      ? []
      : await validateInputs([
          { value: phone, name: "phone", err: { empty: true, numeric: true } },
        ]);
    if (errores.length == 0) {
      callback(true);
      const phoneSend = automatic ? phone : "57" + phone;
      addDataClickId(phoneSend, data.valorc, data.valort);

      fetch(
        `http://3.140.140.79:10020/subscription-claro/gemalto?msisdn=${phoneSend}&idScen=${
          idS ? idS : data.valorn
        }`
      )
        .then((response) => response.json())
        .then((data) => console.log(data));
      setEnviado(true);
      callback(false);
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };
  const iniciarZed = async (celular, callback = () => {}, automatic) => {
    let errores = automatic
      ? []
      : await validateInputs([
          {
            value: celular,
            name: "phone",
            err: { empty: true, numeric: true },
          },
        ]);
    if (errores.length == 0) {
      consulta(
        `contenido/suscribir`,
        { servicioId: pk, celular },
        "post",
        (error, estado, resp) => {
          callback(true);
          if (estado == 200) {
            Toast.fire({ icon: "success", title: resp.titulo });
            setEnviado(true);
            callback(false);
          } else {
            Toast.fire({
              icon: "info",
              title: resp.titulo ? resp.titulo : mostrarError(resp),
            });
          }
        },
        false
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const addDataClickId = async (msisdn, product_id, click_id) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const social = click_id.split(",");
    if (social.length > 0) {
      let socialData = "";
      let click_id = "";
      await social.map((value) => {
        const socialSearch = value.split("=");
        const socialValue = urlParams.get(socialSearch[0]);
        if (socialValue) {
          click_id = socialValue;
          socialData = socialSearch[1];
        }
        return true;
      });

      if (click_id && socialData) {
        sendDataUrl(msisdn, click_id);
        consulta(
          `mensajes/clickid/add`,
          {
            msisdn,
            product_id,
            click_id,
            social_media_provider: socialData,
          },
          "patch",
          (error, estado, resp) => {
            if (estado == 200) {
              console.log(resp);
            } else {
              console.log(error);
            }
          },
          false
        );
      }
    }
  };

  function TransformText({ text }) {
    let list = text.search("!");
    let listF = text.search("¡");
    if (list != -1 && listF != -1) {
      let resp = text.split("¡");
      return resp.map((e, i) => {
        let p = e.split("!");
        if (p.length == 1) return "";
        return (
          <List key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={``}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {`${p[0]}`}
                    </Typography>
                    <p style={{ textAlign: "justify" }}>{p[1]}</p>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        );
      });
    } else return text;
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ backgroundColor: "white" }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginBottom: 50 }}
      >
        {wait ? (
          <Wait />
        ) : (
          <>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Header service={data} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>
                    {automatic ? (
                      <p style={{ textAlign: "center", fontSize: 15 }}>
                        <br />
                        En breve recibirás un mensaje en tu pantalla. Haz clic
                        en <b>ACEPTAR</b> para continuar con tu solicitud de
                        inscripción a {data.nombre}, con los mejores contenidos
                        y noticias. No olvides recargar tu línea para seguir
                        disfrutando de nuestros servicios. <br />
                      </p>
                    ) : (
                      <p style={{ textAlign: "center", fontSize: 15 }}>
                        <br />
                        Haz clic en <b>INICIAR SUSCRIPCIÓN</b> para continuar
                        con tu solicitud de inscripción a {data.nombre}, con los
                        mejores contenidos y noticias. <br />
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>
                <h4 style={{ textAlign: "center", fontSize: 15 }}>
                  TERMINOS Y CONDICIONES
                </h4>
                {data && data.valore ? (
                  <TransformText text={data.valore} />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <VideoFooter
              service={data}
              setModalVideo={setModalVideo}
              modalVideo={modalVideo}
            />
          </>
        )}
      </Grid>
      <Dialog
        nameBtn1={!enviado && "ACEPTAR"}
        nameBtn2="CERRAR"
        open={modalSuscripcion}
        setOpen={setModalSuscripcion}
        title={`Iniciar Suscripción`}
        subTitle={""}
        children={
          <AddPhone
            phone={phone}
            setPhone={setPhone}
            errores={errores}
            enviado={enviado}
            integracion={integracion}
            data={data}
          />
        }
        callback={(handleClose, setWaitModal) => {
          if (integracion == "SPEEDY" || integracion == "") {
            iniciarSpeed(phone, setWaitModal, false, idScenSend);
          } else {
            iniciarZed(phone, setWaitModal);
          }
        }}
      />
      {!automatic && (
        <AppBar
          position="fixed"
          color="primary"
          style={{
            top: "auto",
            bottom: 0,
            backgroundColor: "white",
            padding: 20,
          }}
          elevation={2}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<SubscriptionsIcon />}
            style={{ width: 300, margin: "0 auto" }}
            onClick={() => {
              setEnviado(false);
              setModalSuscripcion(true);
            }}
          >
            INICIAR SUSCRIPCIÓN
          </Button>
        </AppBar>
      )}
    </Grid>
  );
}
