import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NotFound, getService, getPermissions } from "../funciones/general.js";
import Template1 from "../views/Template1.js";
import Template2 from "../views/Template2.js";
import Template3 from "../views/Template3.js";
import Template4 from "../views/Template4.js";
import Template5 from "../views/Template5.js";
import Template6 from "../views/Template6.js";
import Template7 from "../views/Template7.js";
import Template8 from "../views/Template8.js";
import Template9 from "../views/Template9.js";
import Template10 from "../views/Template10.js";
import Template11 from "../views/Template11.js";
import Template12 from "../views/Template12.js";
import Template13 from "../views/Template13.js";
import Template14 from "../views/Template14.js";
import Template15 from "../views/Template15.js";
import Template16 from "../views/Template16.js";
import Template17 from "../views/Template17.js";
import Template18 from "../views/Template18.js";
import Template19 from "../views/Template19.js";
import Template20 from "../views/Template20.js";
import Template21 from "../views/Template21.js";
import Template22 from "../views/Template22.js";
import Template23 from "../views/Template23.js";
import Template24 from "../views/Template24.js";
import Template25 from "../views/Template25.js";
import Template26 from "../views/Template26.js";
import Template27 from "../views/Template27.js";
import Template28 from "../views/Template28.js";
import Template29 from "../views/Template29.js";
import Template30 from "../views/Template30.js";
import Template31 from "../views/Template31.js";
import Template32 from "../views/Template32.js";
import Template33 from "../views/Template33.js";
import Template34 from "../views/Template34.js";
import Template35 from "../views/Template35.js";
import Template36 from "../views/Template36.js";
import Template37 from "../views/Template37.js";
import Template38 from "../views/Template38.js";
import Template39 from "../views/Template39.js";
import Template40 from "../views/Template40.js";
import Template41 from "../views/Template41.js";
import Template42 from "../views/Template42.js";
import Template43 from "../views/Template43.js";
import Template44 from "../views/Template44.js";
import Template45 from "../views/Template45.js";

export default function Templates({ ...rest }) {
  let currentUrl = window.location.pathname;
  const params = useParams();
  const [wait, setWait] = useState(true);
  const [service, setService] = useState({});
  const [celularAuth, setCelularAuth] = useState("");
  const [trivia, setTrivia] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setWait(true);
    let { success, data, trivia, celular } = await getService(params.id);
    if (success) {
      setService(data);
      setTrivia(trivia);
      const { uId } = await getPermissions();
      setCelularAuth(celular);
      if (uId) setCelularAuth(`00000000`);
      if (!data.valoro) setError(true);
    } else setError(true);
    setWait(false);
  };
  if (
    currentUrl.includes("admin") ||
    currentUrl.includes("suscripcion") ||
    currentUrl.includes("productos")
  )
    return "";
  else if (wait) return "";
  else if (error) return <NotFound />;
  switch (service.valoro.codigo) {
    case "tmp1":
      return <Template1 dataService={service} />;
    case "tmp2":
      return <Template2 dataService={service} />;
    case "tmp3":
      return <Template3 dataService={service} />;
    case "tmp4":
      return <Template4 dataService={service} />;
    case "tmp5":
      return <Template5 dataService={service} />;
    case "tmp6":
      return <Template6 dataService={service} />;
    case "tmp7":
      return <Template7 dataService={service} />;
    case "tmp8":
      return <Template8 dataService={service} />;
    case "tmp9":
      return <Template9 dataService={service} />;
    case "tmp10":
      return <Template10 dataService={service} />;
    case "tmp11":
      return <Template11 dataService={service} />;
    case "tmp12":
      return <Template12 dataService={service} />;
    case "tmp13":
      return <Template13 dataService={service} />;
    case "tmp14":
      return <Template14 dataService={service} />;
    case "tmp15":
      return <Template15 dataService={service} />;
    case "tmp16":
      return <Template16 dataService={service} />;
    case "tmp17":
      return <Template17 dataService={service} />;
    case "tmp18":
      return <Template18 dataService={service} />;
    case "tmp19":
      return <Template19 dataService={service} />;
    case "tmp20":
      return <Template20 dataService={service} />;
    case "tmp21":
      return <Template21 dataService={service} />;
    case "tmp22":
      return <Template22 dataService={service} />;
    case "tmp23":
      return <Template23 dataService={service} />;
    case "tmp24":
      return <Template24 dataService={service} />;
    case "tmp25":
      return <Template25 dataService={service} />;
    case "tmp26":
      return <Template26 dataService={service} />;
    case "tmp27":
      return <Template27 dataService={service} />;
    case "tmp28":
      return <Template28 dataService={service} />;
    case "tmp29":
      return <Template29 dataService={service} />;
    case "tmp30":
      return <Template30 dataService={service} />;
    case "tmp31":
      return <Template31 dataService={service} />;
    case "tmp32":
      return <Template32 dataService={service} />;
    case "tmp33":
      return <Template33 dataService={service} />;
    case "tmp34":
      return <Template34 dataService={service} />;
    case "tmp35":
      return <Template35 dataService={service} />;
    case "tmp36":
      return <Template36 dataService={service} />;
    case "tmp37":
      return <Template37 dataService={service} />;
    case "tmp38":
      return <Template38 dataService={service} />;
    case "tmp39":
      return <Template39 dataService={service} />;
    case "tmp40":
      return <Template40 dataService={service} />;
    case "tmp41":
      return <Template41 dataService={service} />;
    case "tmp42":
      return <Template42 dataService={service} />;
    case "tmp43":
      return (
        <Template43
          dataService={service}
          trivia={trivia}
          celularAuth={celularAuth}
        />
      );
    case "tmp44":
      return <Template44 dataService={service} />;
    case "tmp45":
      return <Template45 dataService={service} />;
    default:
      return <NotFound dataService={service} />;
  }
}
