import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import PhoneIcon from "@material-ui/icons/Phone";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import EventNoteIcon from "@material-ui/icons/EventNote";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import useStyles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";

const isCheck = (value, i) =>
  value == i ? { background: `rgb(113,130,237)`, color: "white" } : {};

export default function Footer() {
  const classes = useStyles();
  let currentUrl = window.location.pathname.split("/")[2];
  let value = 0;
  if (currentUrl == "categorias") value = 1;
  if (currentUrl == "publicidad") value = 2;
  if (currentUrl == "usuarios") value = 3;
  if (currentUrl == "trivias") value = 4;

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="primary"
        className={classes.appBar}
        elevation={0}
      >
        <BottomNavigation value={value} showLabels className={classes.root}>
          <BottomNavigationAction
            className={classes.navigation}
            style={isCheck(value, 0)}
            component={Link}
            to={`/admin/servicios`}
            label="Servicios"
            icon={<PhoneIcon />}
          />
          <BottomNavigationAction
            className={classes.navigation}
            style={isCheck(value, 1)}
            component={Link}
            to={`/admin/categorias`}
            label="Categorías"
            icon={<DeviceHubIcon />}
          />
          <BottomNavigationAction
            className={classes.navigation}
            style={isCheck(value, 2)}
            component={Link}
            to={`/admin/publicidad`}
            label="Publicidad"
            icon={<EventNoteIcon />}
          />
          <BottomNavigationAction
            className={classes.navigation}
            style={isCheck(value, 4)}
            component={Link}
            to={`/admin/trivias`}
            label="Trivias"
            icon={<HelpOutlineIcon />}
          />
          <BottomNavigationAction
            className={classes.navigation}
            style={isCheck(value, 3)}
            component={Link}
            to={`/admin/usuarios`}
            label="Usuarios"
            icon={<PersonPinIcon />}
          />
        </BottomNavigation>
      </AppBar>
    </div>
  );
}
