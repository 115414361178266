import {
  drawerWidth,
  transition,
  container
} from "../../material-dashboard-react.js";
import { fade } from '@material-ui/core/styles';

const appStyle = theme => ({
  wrapper: {
    // position: "relative",
    top: "0",
    height: "90vh",
  },
  mainPanel: {

    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    padding: "100px 15px 80px 15px",
    // margin: "50px 0 200px 0",
    minHeight: "calc(100vh - 123px)",
    overflow: "auto",
  },
  contentWait: {
    width: "100%",
    textAlign: 'center',
    cursor: "pointer",
  },
  contentWaitImg: {
    width: "10%",
    minWidth: "150px",
    padding: 0,
  },
  contentWaitText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    // position: 'absolute',
    // top: "50%",
    // bottom: "50%",
    // left: "45%",
    // right: "55%",
  },
  map: {
    marginTop: "70px",
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  rootBottomNavigation: {
    backgroundColor: "Transparent",
    color: "white",
  },

  bottomNavigationAction: {
    color: "#9fa8da",
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nestedList: {
    paddingLeft: theme.spacing(4),
  },
  iconList: {
    color: "#3f51b5",
  },
  iconChip: {
    margin: theme.spacing(0.5),
  },
});

export default appStyle;
