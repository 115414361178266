import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { NotFound } from "../funciones/general.js";


import routes from "../routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";


let ps;


const switchRoutes = (auth) => {
  return (
    <Switch>
      {routes.map((prop, key) => {
        if ((prop.layout === "/admin" || prop.layout === "/") && (prop.auth == auth)) {
          return (
            <Route
              exact
              path={`${prop.layout}${prop.path}`}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Route component={() => <NotFound />} />
    </Switch>
  )
}

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  let auth = localStorage.getItem('token') ? true : false;
  return <div >{switchRoutes(auth)}</div>;
}
