import React, { useState, useEffect } from "react";
import moment from "moment";
import * as XLSX from "xlsx";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  ListSubheader,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  ListItemSecondaryAction,
  Menu,
  Badge,
  Chip,
  ListItemIcon,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  ListItem,
  List,
  Collapse,
  CardContent,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Typography,
  FormControlLabel,
  Tooltip,
  IconButton,
  Fab,
  TextField,
  Avatar,
  Button,
  Table,
  TableBody,
  Grid,
  TableRow,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import FlagIcon from "@material-ui/icons/Flag";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CategoryIcon from "@material-ui/icons/Category";
import VideocamIcon from "@material-ui/icons/Videocam";
import SettingsIcon from "@material-ui/icons/Settings";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import EditIcon from "@material-ui/icons/Edit";
import TodayIcon from "@material-ui/icons/Today";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ViewListIcon from "@material-ui/icons/ViewList";
import CodeIcon from "@material-ui/icons/Code";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Dialog from "../components/Dialog/Dialog.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardFooter from "../components/Card/CardFooter.js";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ButtonFloating from "../components/CustomButtons/ButtonFloating.js";
import InputFile from "../components/CustomInput/InputFile.js";
import SearchSelect from "../components/CustomInput/SearchSelect.js";
import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesAdmin from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import loading from "../assets/img/loading.gif";
import settingImg from "../assets/img/setting.png";
import ClearIcon from "@material-ui/icons/Clear";
import BackupIcon from "@material-ui/icons/Backup";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
//Funtions
import {
  Empty,
  transfornDataSelet,
  validateInputs,
  API,
  mostrarError,
  crear_form_data,
  formulario,
  consulta,
  Toast,
  colorDinamic,
  iconoDinamic,
  getError,
  transfornDataSeletPermission,
  transfornDataSeletPermissionSecundario,
} from "../funciones/general";

const useStyles = makeStyles(styles);
const useStylesAdmin = makeStyles(stylesAdmin);

function TabsMessages({ setMessageSelect, setDetalle }) {
  const classes = useStylesAdmin();
  const [value, setValue] = React.useState("welcome");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMessageSelect(newValue);
    setDetalle(false);
  };

  return (
    <div className="tabs_modal">
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.rootBottomNavigation}
        showLabels
      >
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Welcome"
          value="welcome"
          icon={<AssistantPhotoIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Indice"
          value="indice"
          icon={<LocationOnIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Default"
          value="default"
          icon={<SpeakerNotesIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Subscription"
          value="subscription"
          icon={<SubscriptionsIcon />}
        />
      </BottomNavigation>
    </div>
  );
}

function AddCsv({
  dataCsv,
  errorsCsv,
  waitMessage,
  reset,
  handleFileUpload,
  archivo,
  setArchivo,
  errores,
}) {
  const classes = useStylesAdmin();
  const [anchorErroresCsv, setAnchorErroresCsv] = React.useState(null);

  const handleClickErroresCsv = (event) => {
    setAnchorErroresCsv(event.currentTarget);
  };

  const handleCloseMenuErroresCsv = () => {
    setAnchorErroresCsv(null);
  };

  return (
    <div>
      {
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={1} sm={1} md={1}>
                <IconButton
                  onClick={() => reset()}
                  edge="end"
                  aria-label="delete"
                  color="secondary"
                >
                  <ClearIcon />
                </IconButton>
              </GridItem>
              <GridItem xs={10} sm={8} md={6}>
                <input
                  style={{ display: "none" }}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  // ref={this.fileInput}
                  type="file"
                  id="csv_masivo"
                  name="archivo"
                  label="Adjuntar Csv"
                  onChange={(e) => {
                    handleFileUpload(e);
                    setArchivo(e.target.value);
                  }}
                />

                <InputFile
                  label="Adjuntar Csv"
                  id="csv_masivo"
                  value={archivo}
                  error={getError("archivo", errores) && true}
                  helperText={getError("archivo", errores)}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <ListItem>
                    <ListItemText
                      primary={`${dataCsv.length} Mensajes Cargados `}
                    />
                    {errorsCsv.length > 0 && (
                      <ListItemSecondaryAction>
                        <Badge
                          badgeContent={errorsCsv.length}
                          color="primary"
                          onClick={handleClickErroresCsv}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<ErrorOutlineIcon />}
                            onClick={handleClickErroresCsv}
                          >
                            Errores
                          </Button>
                        </Badge>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorErroresCsv}
                          keepMounted
                          open={Boolean(anchorErroresCsv)}
                          onClose={handleCloseMenuErroresCsv}
                        >
                          <div style={{ width: 350, padding: 15 }}>
                            {errorsCsv.map(({ row, description }) => {
                              return (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    Fila {row + 2} : {description}
                                  </Typography>
                                  <br />
                                </>
                              );
                            })}
                            <Button
                              onClick={() => setAnchorErroresCsv(null)}
                              color="primary"
                            >
                              Entiendo{" "}
                            </Button>
                          </div>
                        </Menu>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </ListSubheader>
              }
              className={classes.rootList}
            >
              {waitMessage ? (
                <WaitData />
              ) : (
                <div>
                  {dataCsv.length == 0 && <Empty />}
                  {dataCsv.map(
                    (
                      { start_date, end_date, content, description, warning },
                      i
                    ) => {
                      return (
                        <>
                          <Divider />
                          <ListItem key={i}>
                            <ListItemAvatar>
                              <Avatar
                                style={{
                                  backgroundColor: warning
                                    ? "#ffeb3b"
                                    : "#009688",
                                }}
                              >
                                {i + 1}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                description && (
                                  <span>
                                    <br />
                                    <b>Mensaje : </b> {description}{" "}
                                  </span>
                                )
                              }
                              secondary={
                                <div style={{ textAlign: "justify" }}>
                                  <span>
                                    <b>Fecha Inicio : </b> {start_date}
                                  </span>
                                  <br />
                                  <span>
                                    <b>Fecha Fin : </b> {end_date}
                                  </span>
                                  <br />
                                  <span>
                                    <b>Contenido : </b> {content}{" "}
                                  </span>
                                </div>
                              }
                            />
                          </ListItem>
                        </>
                      );
                    }
                  )}
                </div>
              )}
            </List>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

function AddMesagge({
  nombre,
  setNombre,
  descripcion,
  setDescripcion,
  errores,
}) {
  return (
    <div>
      {
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setNombre(value)}
                  value={nombre}
                  error={getError("content", errores) && true}
                  helperText={getError("content", errores)}
                  label="Content"
                  fullWidth
                  required
                  multiline
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setDescripcion(value)}
                  value={descripcion}
                  error={getError("short_code", errores) && true}
                  helperText={getError("short_code", errores)}
                  label="Short Code"
                  fullWidth
                  required
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

function AddMesaggeDynamic({
  nombre,
  setNombre,
  descripcion,
  setDescripcion,
  setProducto,
  producto,
  errores,
}) {
  return (
    <div>
      {
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setDescripcion(value)}
                  value={descripcion}
                  id="datetime-local-inicio"
                  label="Fecha Inicio"
                  type="datetime-local"
                  defaultValue="2021-05-20T10:30"
                  error={getError("start_date", errores) && true}
                  helperText={getError("start_date", errores)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setProducto(value)}
                  value={producto}
                  id="datetime-local-fin"
                  label="Fecha Fin"
                  type="datetime-local"
                  error={getError("end_date", errores) && true}
                  helperText={getError("end_date", errores)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setNombre(value)}
                  value={nombre}
                  error={getError("content", errores) && true}
                  helperText={getError("content", errores)}
                  label="Content"
                  fullWidth
                  required
                  multiline
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

function ContentMessages({
  detalle,
  setModalArchivoCsv,
  setDetalle,
  setDataMessageDynamic,
  setProducto,
  setModalAddmessageDynamic,
  deleteMessageDynamic,
  messagesDynamic,
  getMessagesDynamic,
  setTipo,
  setDescripcion,
  setNombre,
  messages,
  messageSelect,
  waitMessage,
  setModalAddmessage,
  deleteMessage,
}) {
  const classes = useStylesAdmin();
  const [open, setOpen] = React.useState(true);
  const [idMessageDynamic, setIdMessageDynamic] = React.useState(0);
  const [UidMessages, setUidMessages] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElDetalle, setAnchorElDetalle] = React.useState(null);

  let callbackDelete = () =>
    deleteMessageDynamic(idMessageDynamic, () =>
      getMessagesDynamic(UidMessages)
    );

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuDetalle = (event) => {
    setAnchorElDetalle(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenuDetalle = () => {
    setAnchorElDetalle(null);
  };

  let existe = messages.find((e) => e[5] == messageSelect);
  if (waitMessage) return <WaitData />;
  if (existe) {
    if (!detalle) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {messageSelect == "subscription" && (
                <Chip
                  onClick={() => {
                    getMessagesDynamic(existe[0], () => setDetalle(true));
                    setUidMessages(existe[0]);
                  }}
                  icon={<ViewListIcon />}
                  label="Detalle"
                  color="default"
                  variant="outlined"
                  className={classes.iconChip}
                />
              )}
              <Chip
                onClick={() => {
                  setTipo("update");
                  setNombre(existe[1]);
                  setDescripcion(existe[8]);
                  setModalAddmessage(true);
                }}
                icon={<EditIcon />}
                label="Modificar"
                color="primary"
                variant="outlined"
                className={classes.iconChip}
              />
              <Chip
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickMenu}
                icon={<DeleteForeverIcon />}
                label="Eliminar"
                color="secondary"
                variant="outlined"
                className={classes.iconChip}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <div style={{ width: 300, padding: 15 }}>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Tener en cuenta que no podrá revertir esta acción ¿ Estas
                    seguro ?.
                  </Typography>
                  <br />
                  <Button onClick={() => setAnchorEl(null)} color="primary">
                    {" "}
                    No, Cerrar{" "}
                  </Button>
                  <Button
                    onClick={() => {
                      deleteMessage(existe[0]);
                      setAnchorEl(null);
                    }}
                  >
                    Si, Eliminar{" "}
                  </Button>
                </div>
              </Menu>
              <br />
              Información Actual
            </ListSubheader>
          }
          className={classes.rootList}
        >
          <ListItem button>
            <ListItemIcon>
              <DateRangeIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Modificado : ${existe[6]}`} />
          </ListItem>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <InboxIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary="Contenido" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nestedList}>
                <ListItemText primary={existe[1]} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button>
            <ListItemIcon>
              <PlaylistAddCheckIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Cron Rule : ${existe[2]}`} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <LowPriorityIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Dynamic Template : ${existe[3]}`} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TodayIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Event Amount : ${existe[4]}`} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <LocalOfferIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Producto Id : ${existe[7]}`} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <CodeIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Short Code : ${existe[8]}`} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <LabelImportantIcon className={classes.iconList} />
            </ListItemIcon>
            <ListItemText primary={`Unlimited Events : ${existe[9]}`} />
          </ListItem>
        </List>
      );
    } else {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <ListItem>
                <ListItemText
                  primary={
                    <div>
                      <IconButton
                        onClick={() => setDetalle(false)}
                        edge="end"
                        aria-label="delete"
                      >
                        <ArrowBackIcon />
                      </IconButton>{" "}
                      Detalle Mensajes - {`${messagesDynamic.length} Registros`}
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                      setTipo("add");
                      setProducto(`${moment().format("YYYY-MM-DD")}T23:59`);
                      setDescripcion(`${moment().format("YYYY-MM-DD")}T00:00`);
                      setModalAddmessageDynamic(true);
                    }}
                  >
                    <AddIcon />
                  </Fab>
                  <Fab
                    style={{ marginLeft: 3, backgroundColor: "#009688" }}
                    size="small"
                    color="primary"
                    onClick={() => {
                      setModalArchivoCsv(true);
                    }}
                  >
                    <BackupIcon />
                  </Fab>
                </ListItemSecondaryAction>
              </ListItem>
            </ListSubheader>
          }
          className={classes.rootList}
        >
          {messagesDynamic.length == 0 && <Empty />}
          {messagesDynamic.map((e, i) => {
            return (
              <>
                <Divider />
                <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#607d8b" }}>
                      {i + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div>
                        <Chip
                          onClick={() => {
                            setDataMessageDynamic(e);
                            let f1 = e[3] ? e[3].split(".")[0] : "";
                            let f2 = e[2] ? e[2].split(".")[0] : "";
                            setTipo("update");
                            setNombre(e[1]);
                            setDescripcion(f1);
                            setProducto(f2);
                            setModalAddmessageDynamic(true);
                          }}
                          icon={<EditIcon />}
                          label="Modificar"
                          color="primary"
                          variant="outlined"
                          className={classes.iconChip}
                        />

                        <Chip
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => {
                            setIdMessageDynamic(e[0]);
                            handleClickMenuDetalle(event);
                          }}
                          icon={<DeleteForeverIcon />}
                          label="Eliminar"
                          color="secondary"
                          variant="outlined"
                          className={classes.iconChip}
                        />
                      </div>
                    }
                    secondary={
                      <div style={{ textAlign: "justify" }}>
                        <span>
                          <b>Fecha Inicio : </b> {e[3]}
                        </span>
                        <br />
                        <span>
                          <b>Fecha Fin : </b> {e[2]}
                        </span>
                        <br />
                        <span>
                          <b>Contenido : </b> {e[1]}
                        </span>
                      </div>
                    }
                  />
                </ListItem>
              </>
            );
          })}

          <Menu
            id="simple-menu"
            anchorEl={anchorElDetalle}
            keepMounted
            open={Boolean(anchorElDetalle)}
            onClose={handleCloseMenuDetalle}
          >
            <div style={{ width: 300, padding: 15 }}>
              <Typography component="span" variant="body2" color="textPrimary">
                Tener en cuenta que no podrá revertir esta acción ¿ Estas seguro
                ?.
              </Typography>
              <br />
              <Button onClick={() => setAnchorElDetalle(null)} color="primary">
                {" "}
                No, Cerrar{" "}
              </Button>
              <Button
                onClick={() => {
                  callbackDelete();
                  setAnchorElDetalle(null);
                }}
              >
                Si, Eliminar{" "}
              </Button>
            </div>
          </Menu>
        </List>
      );
    }
  } else {
    return (
      <div
        className={classes.contentWait}
        onClick={() => {
          setTipo("add");
          setModalAddmessage(true);
        }}
      >
        <img src={settingImg} alt="" width={100} />
        <div className={classes.contentWaitText}>
          No se encontro la configuración, clic aquí para crear
        </div>
      </div>
    );
  }
}

function WaitData({ text = "Cargando...", margin = "10% 0 0 0" }) {
  const classes = useStylesAdmin();
  return (
    <div className={classes.contentWait} style={{ margin }}>
      <img src={loading} alt="" className={classes.contentWaitImg} />
      <div className={classes.contentWaitText}>{text}</div>
    </div>
  );
}

function DetailService({ data }) {
  const classes = useStyles();

  let {
    id,
    nombre,
    descripcion,
    valorc,
    valord,
    valore,
    valorf,
    valorg,
    valori,
    valorj,
    valork,
    valorl,
    valorm,
    valorn,
    valoro,
    valorq,
    valorr,
    fecha_registro,
    estado,
    usuario_registro,
    total,
    archivo,
    archivo2,
    archivo3,
    archivo4,
    archivo5,
    archivo6,
    archivo7,
    archivo9,
    valors,
    valort,
    valoru,
    valorv,
    valorw,
    valorx,
    valory,
  } = data;
  let fecha = moment(fecha_registro).format("YYYY-MM-DD, h:mm a");
  return (
    <Table>
      <TableBody>
        <TableRow key={-24}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Integración Activa
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorr}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-23}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Plantilla
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valoro && valoro.nombre}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-2}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Código
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {id}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-1}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              #Contenido
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {total}
            </Grid>
          </Grid>
        </TableRow>
        {valorq && (
          <TableRow key={-17}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Google Analytics
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {valorq}
              </Grid>
            </Grid>
          </TableRow>
        )}
        <TableRow key={0}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Titulo
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorf}
            </Grid>
          </Grid>
        </TableRow>
        {valors && (
          <TableRow key={-34}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Logo pagina WEB
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                <span onClick={() => window.open(`${valors}`)}>VER</span>
              </Grid>
            </Grid>
          </TableRow>
        )}
        {archivo7 && (
          <TableRow key={-30}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Logo
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                <span onClick={() => window.open(`${API}${archivo7}`)}>
                  VER
                </span>
              </Grid>
            </Grid>
          </TableRow>
        )}
        {archivo5 && (
          <TableRow key={-16}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Fondo
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                <span onClick={() => window.open(`${API}${archivo5}`)}>
                  VER
                </span>
              </Grid>
            </Grid>
          </TableRow>
        )}
        {archivo6 && (
          <TableRow key={-21}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Fondo App
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                <span onClick={() => window.open(`${API}${archivo6}`)}>
                  VER
                </span>
              </Grid>
            </Grid>
          </TableRow>
        )}
        <TableRow key={1}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Banner
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              <span onClick={() => window.open(`${API}${archivo}`)}>VER</span>
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-11}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Footer
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              <span onClick={() => window.open(`${API}${archivo2}`)}>VER</span>
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-14}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Banner App
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              <span onClick={() => window.open(`${API}${archivo3}`)}>VER</span>
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-15}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Menu App
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              <span onClick={() => window.open(`${API}${archivo4}`)}>VER</span>
            </Grid>
          </Grid>
        </TableRow>

        {archivo9 && (
          <TableRow key={-35}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Video suscripción
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                <span onClick={() => window.open(`${API}${archivo9}`)}>
                  VER
                </span>
              </Grid>
            </Grid>
          </TableRow>
        )}
        <TableRow key={2}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Nombre
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {nombre}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={4}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Producto
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorc}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={5}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Referencia
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valord}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-20}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Periodicidad
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorm}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-22}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              idScen
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorn}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-28}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Click
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valort}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-31}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              CountryCode
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorx}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-32}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              CarrierCode
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valory}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-33}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Campaña
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorv}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-29}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              URL registro
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorw}
            </Grid>
          </Grid>
        </TableRow>
        {valorj && (
          <TableRow key={-17}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Link Facebook
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {<span onClick={() => window.open(valorj)}>VER</span>}
              </Grid>
            </Grid>
          </TableRow>
        )}
        {valork && (
          <TableRow key={-18}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Link Twitter
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {<span onClick={() => window.open(valork)}>VER</span>}
              </Grid>
            </Grid>
          </TableRow>
        )}
        {valorl && (
          <TableRow key={-19}>
            <Grid container className={classes.tableRow}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowTitle}
              >
                Link Instagram
              </Grid>
              <Grid
                align="left"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.tableRowVal}
              >
                {<span onClick={() => window.open(valorl)}>VER</span>}
              </Grid>
            </Grid>
          </TableRow>
        )}
        <TableRow key={6}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Descripción
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {descripcion}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={7}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Terminos y Condiciones
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valore}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-12}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Políticas de privacidad
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valorg}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-13}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Preguntas Frecuentes
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valori}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={-123}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              ¿ Como suscribirse ?
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {valoru}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={8}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Estado Actual
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {estado == 1 ? "Activo" : "Inactivo"}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={9}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Registrado por
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {usuario_registro && usuario_registro.usuario}
            </Grid>
          </Grid>
        </TableRow>
        <TableRow key={10}>
          <Grid container className={classes.tableRow}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowTitle}
            >
              Fecha Registro
            </Grid>
            <Grid
              align="left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.tableRowVal}
            >
              {fecha}
            </Grid>
          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function AddService({
  callback = () => {},
  setProveedor,
  proveedor,
  idScen,
  setIdScen,
  idGoogle,
  setIdGoogle,
  setCountryCode,
  setCarrierCode,
  countryCode,
  carrierCode,
  template,
  templates,
  setTemplate,
  tipo,
  twitter,
  archivo6,
  setArchivo6,
  periodicidad,
  setPeriodicidad,
  setTwitter,
  Instagram,
  setInstagram,
  facebook,
  setFacebook,
  nombre,
  setQuestions,
  setSubscribe,
  setCampana,
  setUrl,
  setClick,
  setArchivo4,
  setArchivo5,
  archivo5,
  setArchivo7,
  setArchivo9,
  archivo7,
  archivo9,
  linkPage,
  setArchivo3,
  setLinkPage,
  archivo3,
  archivo4,
  questions,
  subscribe,
  campana,
  url,
  click,
  setArchivo2,
  setPoliticas,
  politicas,
  archivo2,
  setNombre,
  descripcion,
  setDescripcion,
  producto,
  setProducto,
  referencia,
  setReferencia,
  setTyc,
  tyc,
  setArchivo,
  archivo,
  titulo,
  setTitulo,
  errores,
}) {
  return (
    <div>
      {
        <GridContainer>
          {tipo == "update" && (
            <GridItem xs={12} sm={12} md={12}>
              <Button
                onClick={callback}
                variant="contained"
                color="secondary"
                startIcon={<DeleteForeverIcon />}
              >
                Eliminar Fondos y video
              </Button>
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={12}>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Integración a utilizar*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={proveedor}
                    label="integración a utilizar"
                    error={getError("proveedor", errores) && true}
                    onChange={({ target: { value } }) => setProveedor(value)}
                  >
                    <MenuItem value="SPEEDY">SPEEDY</MenuItem>
                    <MenuItem value="ZED">ZED MOVISTAR</MenuItem>
                    <MenuItem value="ZED_DIGITEL">ZED DIGITEL</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {getError("proveedor", errores)}
                  </FormHelperText>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <SearchSelect
                  datos={templates}
                  cambiarEstado={(value) => setTemplate(value)}
                  valor={template}
                  id="select-template"
                  nombre="¿ Plantila para el servicio ?"
                  placeholder="Seleccione Plantilla"
                  error={getError("template", errores) && true}
                  helperText={getError("template", errores)}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <input
                  style={{ display: "none" }}
                  accept="video/*"
                  // ref={this.fileInput}
                  type="file"
                  id="video_suscripcion"
                  name="archivo"
                  label="Video suscripción"
                  onChange={({ target: { value } }) => setArchivo9(value)}
                />
                <InputFile
                  label="Video suscripción"
                  id="video_suscripcion"
                  value={archivo9}
                />
              </GridItem>
            </GridContainer>

            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="logo_servicio"
                  name="archivo"
                  label="Adjuntar Logo"
                  onChange={({ target: { value } }) => setArchivo7(value)}
                />
                <InputFile
                  label="Adjuntar Logo"
                  id="logo_servicio"
                  value={archivo7}
                />
              </GridItem>
            </GridContainer>

            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setLinkPage(value)}
                  value={linkPage}
                  label="Link Logo pagina WEB"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="fondo_servicio"
                  name="archivo"
                  label="Adjuntar Fondo"
                  onChange={({ target: { value } }) => setArchivo5(value)}
                />
                <InputFile
                  label="Adjuntar Fondo"
                  id="fondo_servicio"
                  value={archivo5}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="fondo_servicio_app"
                  name="archivo"
                  label="Adjuntar Fondo App"
                  onChange={({ target: { value } }) => setArchivo6(value)}
                />
                <InputFile
                  label="Adjuntar Fondo App"
                  id="fondo_servicio_app"
                  value={archivo6}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="banner_servicio"
                  name="archivo"
                  label="Adjuntar Banner"
                  onChange={({ target: { value } }) => setArchivo(value)}
                />
                <InputFile
                  label="Adjuntar Banner"
                  id="banner_servicio"
                  value={archivo}
                  error={getError("archivo", errores) && true}
                  helperText={getError("archivo", errores)}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="banner_app_servicio"
                  name="archivo"
                  label="Adjuntar Banner"
                  onChange={({ target: { value } }) => setArchivo3(value)}
                />
                <InputFile
                  label="Adjuntar Banner App"
                  id="banner_app_servicio"
                  value={archivo3}
                  error={getError("archivo3", errores) && true}
                  helperText={getError("archivo3", errores)}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="menu_app_servicio"
                  name="archivo"
                  label="Adjuntar Menu App"
                  onChange={({ target: { value } }) => setArchivo4(value)}
                />
                <InputFile
                  label="Adjuntar Menu App"
                  id="menu_app_servicio"
                  value={archivo4}
                  error={getError("archivo4", errores) && true}
                  helperText={getError("archivo4", errores)}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  // ref={this.fileInput}
                  type="file"
                  id="footer_servicio"
                  name="archivo"
                  label="Adjuntar Footer"
                  onChange={({ target: { value } }) => setArchivo2(value)}
                />
                <InputFile
                  label="Adjuntar Footer"
                  id="footer_servicio"
                  value={archivo2}
                  error={getError("archivo2", errores) && true}
                  helperText={getError("archivo2", errores)}
                />
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setNombre(value)}
                  value={nombre}
                  error={getError("nombre", errores) && true}
                  helperText={getError("nombre", errores)}
                  label="Nombre"
                  fullWidth={true}
                  required
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setTitulo(value)}
                  value={titulo}
                  label="Titulo"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setFacebook(value)}
                  value={facebook}
                  label="Link Facebook"
                  fullWidth={true}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setTwitter(value)}
                  value={twitter}
                  label="Link Twitter"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setInstagram(value)}
                  value={Instagram}
                  label="Link Instagram"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setProducto(value)}
                  value={producto}
                  error={getError("producto", errores) && true}
                  helperText={getError("producto", errores)}
                  label="Producto"
                  fullWidth
                  required
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setReferencia(value)}
                  value={referencia}
                  label="Referencia"
                  error={getError("referencia", errores) && true}
                  helperText={getError("referencia", errores)}
                  required
                  fullWidth
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setPeriodicidad(value)}
                  value={periodicidad}
                  error={getError("periodicidad", errores) && true}
                  helperText={getError("periodicidad", errores)}
                  label="Periodicidad"
                  fullWidth={true}
                  required
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setIdScen(value)}
                  value={idScen}
                  error={getError("idScen", errores) && true}
                  helperText={getError("idScen", errores)}
                  label="idScen"
                  fullWidth={true}
                  required
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setClick(value)}
                  value={click}
                  label="Click"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setCountryCode(value)}
                  value={countryCode}
                  label="CountryCode"
                  fullWidth
                  required
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <br />
                <TextField
                  onChange={({ target: { value } }) => setCarrierCode(value)}
                  value={carrierCode}
                  label="CarrierCode"
                  required
                  fullWidth
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setDescripcion(value)}
                  value={descripcion}
                  label="Descripción"
                  fullWidth={true}
                  multiline
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setTyc(value)}
                  value={tyc}
                  label="Terminos y Condiciones"
                  fullWidth={true}
                  multiline
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setPoliticas(value)}
                  value={politicas}
                  label="Políticas de privacidad"
                  fullWidth={true}
                  multiline
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setQuestions(value)}
                  value={questions}
                  label="Preguntas Fecuentes"
                  fullWidth={true}
                  multiline
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setSubscribe(value)}
                  value={subscribe}
                  label="¿ Como suscribirse ?"
                  fullWidth={true}
                  multiline
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setIdGoogle(value)}
                  value={idGoogle}
                  label="Google Analytics"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setCampana(value)}
                  value={campana}
                  label="Campaña"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setUrl(value)}
                  value={url}
                  label="URL registro"
                  fullWidth={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

const typeFile = (file_id, archivo) => {
  let type = "";
  let file = document.getElementById(file_id)
    ? document.getElementById(file_id).files[0]
    : "";
  if (file && archivo) type = file.type.split("/")[0];
  return type;
};

function AddContentService({
  nombre,
  getSubCategorys,
  setNombre,
  descripcion,
  setDescripcion,
  archivo,
  archivo2,
  setArchivo2,
  setArchivo,
  categoria,
  setCategoria,
  fechaPublicacion,
  dataCategory,
  setFechaPublicacion,
  tipoCategoria,
  setTipoCategoria,
  tipoCategoriaSub,
  setTipoCategoriaSub,
  dataCategorySub,
  errores,
}) {
  let type = typeFile("contenido_servicio", archivo);
  return (
    <div>
      {
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={categoria}
                      onChange={() => setCategoria(!categoria)}
                      name="categoria"
                      color="primary"
                    />
                  }
                  label="Contenido Premium"
                />
              </GridItem>
            </GridContainer>
            {categoria && (
              <div>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <SearchSelect
                      datos={dataCategory}
                      cambiarEstado={(value) => {
                        setTipoCategoria(value);
                        getSubCategorys(value.value);
                      }}
                      valor={tipoCategoria}
                      id="select-categoria"
                      nombre="¿ Categorizar contenido ?"
                      placeholder="Seleccione Categoría"
                      error={getError("tipoCategoria", errores) && true}
                      helperText={getError("tipoCategoria", errores)}
                    />
                  </GridItem>
                </GridContainer>

                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <SearchSelect
                      datos={dataCategorySub}
                      cambiarEstado={(value) => setTipoCategoriaSub(value)}
                      valor={tipoCategoriaSub}
                      id="select-sub-categoria"
                      nombre="¿ sub-categorizar del contenido ?"
                      placeholder="Seleccione Sub-Categoría"
                      // error={getError('tipoCategoria', errores) && true}
                      // helperText={getError('tipoCategoria', errores)}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )}
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  style={{ display: "none" }}
                  // ref={this.fileInput}
                  type="file"
                  id="contenido_servicio"
                  name="archivo"
                  label="Adjuntar Contenido"
                  fullWidth
                  onChange={({ target: { value } }) => setArchivo(value)}
                />
                <InputFile
                  required={false}
                  label="Adjuntar Contenido"
                  id="contenido_servicio"
                  value={archivo}
                  error={getError("archivo", errores) && true}
                  helperText={getError("archivo", errores)}
                />
              </GridItem>
            </GridContainer>
            {type != "image" && type != "" && (
              <div>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      // ref={this.fileInput}
                      type="file"
                      id="contenido_servicio_preview"
                      name="archivo"
                      label="Adjuntar Preview"
                      onChange={({ target: { value } }) => setArchivo2(value)}
                    />
                    <InputFile
                      label="Adjuntar Preview"
                      id="contenido_servicio_preview"
                      value={archivo2}
                      error={getError("archivo2", errores) && true}
                      helperText={getError("archivo2", errores)}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )}

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={getError("fechaPublicacion", errores) && true}
                    helperText={getError("fechaPublicacion", errores)}
                    style={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Fecha Publicación"
                    format="MM/dd/yyyy"
                    value={fechaPublicacion}
                    onChange={(value) => setFechaPublicacion(value)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setNombre(value)}
                  value={nombre}
                  error={getError("nombre", errores) && true}
                  helperText={getError("nombre", errores)}
                  label="Nombre"
                  fullWidth
                  required
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  onChange={({ target: { value } }) => setDescripcion(value)}
                  value={descripcion}
                  label="Descripción"
                  multiline
                  fullWidth
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

function OpenServiceContent({
  wait,
  setProveedor,
  updateContent,
  dataContent,
  setModalAddContent,
  setTipoCategoria,
  setDataContentSele,
  setTipo,
  setDescripcion,
  setNombre,
  setArchivo,
  setCategoria,
  setFechaPublicacion,
}) {
  return (
    <div>
      {/* {<Empty />} */}
      <ShowService
        dataContent={dataContent}
        updateContent={updateContent}
        setDataContentSele={setDataContentSele}
        setModalAddContent={setModalAddContent}
        setTipo={setTipo}
        setDescripcion={setDescripcion}
        setNombre={setNombre}
        setArchivo={setArchivo}
        setCategoria={setCategoria}
        setFechaPublicacion={setFechaPublicacion}
        setTipoCategoria={setTipoCategoria}
        wait={wait}
      />
      <div style={{ padding: 0, margin: 0, width: "30px", textAlign: "right" }}>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            position: "absolute",
            bottom: 10,
            bottom: "5%",
            right: "2%",
          }}
          onClick={() => {
            setModalAddContent(true);
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}

function ShowService({
  wait,
  dataContent,
  setTipoCategoria,
  updateContent,
  setDataContentSele,
  setModalAddContent,
  setTipo,
  setDescripcion,
  setNombre,
  setCategoria,
  setFechaPublicacion,
  setArchivo,
}) {
  const classes = useStyles();

  const actions = (rowData) => {
    let {
      id,
      nombre,
      fecha_publicacion,
      categoria,
      estado,
      descripcion,
      tipo,
      archivo2,
      archivo,
    } = rowData;
    const update = () => {
      setDataContentSele({ id, nombre, descripcion });
      setDescripcion(descripcion);
      setNombre(nombre);
      setFechaPublicacion(fecha_publicacion);
      setCategoria(categoria == 1);
      if (tipo && tipo.id)
        setTipoCategoria({ value: tipo.id, label: tipo.nombre });
      // setArchivo():
      setTipo("update");
      setModalAddContent(true);
    };
    const changeState = (id, estado) => updateContent(id, { estado });

    let btnOff = (
      <Tooltip title="Desactivar" onClick={() => changeState(id, 0)}>
        <IconButton style={{ color: "#b71c1c" }}>
          <VisibilityOffIcon fontSize="small" />{" "}
        </IconButton>
      </Tooltip>
    );
    let btnOn = (
      <Tooltip title="Activar" onClick={() => changeState(id, 1)}>
        <IconButton style={{ color: "#009688" }}>
          <VisibilityIcon fontSize="small" />{" "}
        </IconButton>
      </Tooltip>
    );
    let btnEditar = (
      <Tooltip title="Editar" onClick={() => update(rowData)}>
        <IconButton style={{ color: "#2196f3" }}>
          <CreateIcon fontSize="small" />{" "}
        </IconButton>
      </Tooltip>
    );
    let btnAbrir = (
      <Tooltip title="Abrir">
        <IconButton
          style={{ color: "#607d8b" }}
          onClick={() => window.open(`${API}${archivo}`)}
        >
          <ImportContactsIcon fontSize="small" />{" "}
        </IconButton>
      </Tooltip>
    );
    let btnEstado = estado == "1" ? btnOff : btnOn;
    return (
      <div>
        {btnAbrir} {btnEditar} {btnEstado}
      </div>
    );
  };
  const [expanded, setExpanded] = useState(0);
  if (wait == "content") return <WaitData margin="0" />;
  else if (wait == "" && dataContent.length == 0) return <Empty />;
  return (
    <List className={classes.rootList}>
      {dataContent.map((data) => {
        let {
          id,
          nombre,
          fecha_publicacion,
          categoria,
          tipo,
          estado,
          archivo2,
          descripcion,
          sub_tipo,
        } = data;
        return (
          <>
            <ListItem
              key={id}
              button
              className={classes.itemList}
              onClick={() => setExpanded(expanded == id ? 0 : id)}
            >
              <ListItemAvatar>
                <Avatar alt="Preview" src={API + archivo2} />
              </ListItemAvatar>
              <Grid container>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {nombre.toUpperCase()}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <div
                          className={classes.stats}
                          style={{ textAlign: "left" }}
                        >
                          <CategoryIcon />
                          {`Categoría / Sub-Categoría : ${
                            tipo ? tipo.nombre : "Ninguna"
                          } / ${sub_tipo ? sub_tipo.nombre : "Ninguna"}`}
                          <br />
                          <MonetizationOnIcon />
                          {`Tipo : ${categoria == 1 ? "Premium" : "Gratis"}`}
                          <br />
                          <FlagIcon />
                          {`Estado : ${estado == 1 ? "Activo" : "Inactivo"}`}
                          <br />
                          <DateRange />
                          {`Publicado el : ${moment(fecha_publicacion).format(
                            "YYYY-MM-DD"
                          )}`}
                        </div>
                      </React.Fragment>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  {actions(data)}
                </Grid>
                <Grid item lg={12}>
                  <Collapse in={id == expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>Descripción:</Typography>
                      <Typography paragraph>{descripcion}</Typography>
                    </CardContent>
                  </Collapse>
                </Grid>
              </Grid>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        );
      })}
    </List>
  );
}

function ShowData({
  getMessages,
  setProveedor,
  setModalSettings,
  setIdGoogle,
  setCountryCode,
  setCarrierCode,
  setTemplate,
  getCategorys,
  setIdScen,
  setPeriodicidad,
  setFacebook,
  setTwitter,
  setInstagram,
  setClick,
  setQuestions,
  setSubscribe,
  setCampana,
  setUrl,
  setPoliticas,
  data,
  setDataSele,
  setModalDetail,
  updateValueGenerica,
  setModalAdd,
  setNombre,
  getContentService,
  setDescripcion,
  setTitulo,
  setProducto,
  setReferencia,
  setTyc,
  setTipo,
  setModalDetailContent,
}) {
  const classes = useStyles();

  const showDataUpdate = (e) => {
    setModalAdd(true);
    setDataSele(e);
    setNombre(e.nombre);
    setDescripcion(e.descripcion);
    setTitulo(e.valorf);
    setProducto(e.valorc);
    setReferencia(e.valord);
    setTyc(e.valore);
    setPoliticas(e.valorg);
    setQuestions(e.valori);
    setSubscribe(e.valoru);
    setCampana(e.valorv);
    setUrl(e.valorw);
    setClick(e.valort);
    setFacebook(e.valorj);
    setTwitter(e.valork);
    setInstagram(e.valorl);
    setPeriodicidad(e.valorm);
    setIdScen(e.valorn);
    setIdGoogle(e.valorq);
    setCountryCode(e.valorx);
    setCarrierCode(e.valory);
    setProveedor(e.valorr);
    if (e.valoro && e.valoro.id)
      setTemplate({ value: e.valoro.id, label: e.valoro.nombre });
    setTipo("update");
  };

  return data.map((e) => {
    let iconoD = iconoDinamic(parseInt(e.valorb));
    return (
      <GridItem xs={12} sm={6} md={3}>
        <Card
          className={classes.cardItem}
          onClick={() => {
            setDataSele(e);
            setModalDetail(true);
          }}
        >
          <CardHeader color={e.valora} stats icon>
            <CardIcon color={e.valora}>{iconoD.icon()}</CardIcon>
            <p className={classes.cardCategory}>{e.nombre.toUpperCase()}</p>
            <h4 className={classes.cardTitle}>
              <small>#Contenido : {e.total}</small>
            </h4>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats} style={{ textAlign: "left" }}>
              <FlagIcon />
              {`Estado : ${e.estado == 1 ? "Activo" : "Inactivo"}`}
              <br />
              <DateRange />
              {`Creado el ${moment(e.fecha_registro).format(
                "YYYY-MM-DD, h:mm a"
              )}`}
            </div>
          </CardFooter>
        </Card>
        <div className={classes.stats}>
          {e.estado == 1 ? (
            <Tooltip
              className={classes.statsTolltip}
              style={{ backgroundColor: "#b71c1c" }}
              title="Desactivar"
              onClick={() => updateValueGenerica(e.id, { estado: 0 })}
            >
              <IconButton className={classes.statsIcon}>
                <VisibilityOffIcon fontSize="small" />{" "}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              className={classes.statsTolltip}
              style={{ backgroundColor: "#009688" }}
              title="Activar"
              onClick={() => updateValueGenerica(e.id, { estado: 1 })}
            >
              <IconButton className={classes.statsIcon}>
                <VisibilityIcon fontSize="small" />{" "}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            className={classes.statsTolltip}
            style={{ backgroundColor: "#2196f3" }}
            title="Editar"
            onClick={() => showDataUpdate(e)}
          >
            <IconButton className={classes.statsIcon}>
              <CreateIcon fontSize="small" />{" "}
            </IconButton>
          </Tooltip>
          <Tooltip
            className={classes.statsTolltip}
            style={{ backgroundColor: "#673ab7" }}
            title="Contenido"
            onClick={() => {
              setDataSele(e);
              getCategorys(e.id);
              getContentService(e.id);
              setModalDetailContent(true);
            }}
          >
            <IconButton className={classes.statsIcon}>
              <VideocamIcon fontSize="small" />{" "}
            </IconButton>
          </Tooltip>
          <Tooltip
            className={classes.statsTolltip}
            style={{ backgroundColor: "#607d8b" }}
            title="Configurar"
            onClick={() => {
              if (e.valorc) {
                setDataSele(e);
                setModalSettings(true);
                getMessages(e);
              } else {
                Toast.fire({
                  icon: "info",
                  title: "El servicio no tiene el producto asociado.",
                });
              }
            }}
          >
            <IconButton className={classes.statsIcon}>
              <SettingsIcon fontSize="small" />{" "}
            </IconButton>
          </Tooltip>
        </div>
      </GridItem>
    );
  });
}

export default function Dashboard() {
  let currentUrl = window.location.pathname.split("/")[2];
  const mainPanel = React.createRef();
  const classes = useStylesAdmin();
  const [search, setSearch] = useState("");
  const [searchContent, setSearchContent] = useState("");
  const [categoria, setCategoria] = React.useState(true);
  const [fechaPublicacion, setFechaPublicacion] = React.useState(new Date());
  const [tipo, setTipo] = useState("add");
  const [proveedor, setProveedor] = useState("");
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalSettings, setModalSettings] = useState(false);
  const [modalDetailContent, setModalDetailContent] = useState(false);
  const [modalAddContent, setModalAddContent] = useState(false);
  const [modalAddMessage, setModalAddmessage] = useState(false);
  const [modalArchivoCsv, setModalArchivoCsv] = useState(false);
  const [modalAddMessageDynamic, setModalAddmessageDynamic] = useState(false);
  const [wait, setWait] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [titulo, setTitulo] = useState("");
  const [tyc, setTyc] = useState("");
  const [politicas, setPoliticas] = useState("");
  const [producto, setProducto] = useState("");
  const [referencia, setReferencia] = useState("");
  const [archivo, setArchivo] = useState("");
  const [archivo2, setArchivo2] = useState("");
  const [archivo3, setArchivo3] = useState("");
  const [archivo4, setArchivo4] = useState("");
  const [archivo5, setArchivo5] = useState("");
  const [archivo6, setArchivo6] = useState("");
  const [archivo7, setArchivo7] = useState("");
  const [archivo9, setArchivo9] = useState("");
  const [linkPage, setLinkPage] = useState("");
  const [questions, setQuestions] = useState("");
  const [subscribe, setSubscribe] = useState("");
  const [campana, setCampana] = useState("");
  const [url, setUrl] = useState("");
  const [click, setClick] = useState("");
  const [periodicidad, setPeriodicidad] = useState("");
  const [idScen, setIdScen] = useState("");
  const [idGoogle, setIdGoogle] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [carrierCode, setCarrierCode] = useState("");
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataCategorySub, setDataCategorySub] = useState([]);
  const [dataContent, setDataContent] = useState([]);
  const [dataContentSele, setDataContentSele] = useState({});
  const [tipoCategoria, setTipoCategoria] = useState(null);
  const [tipoCategoriaSub, setTipoCategoriaSub] = useState(null);
  const [dataSele, setDataSele] = useState({});
  const [errores, setErrores] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messagesDynamic, setMessagesDynamic] = useState([]);
  const [messageSelect, setMessageSelect] = useState("welcome");
  const [waitMessage, setWaitMessage] = useState(true);
  const [dataMessageDynamic, setDataMessageDynamic] = React.useState([]);
  const [detalle, setDetalle] = React.useState(false);
  const [errorsCsv, setErrorsCsv] = useState([]);
  const [dataCsv, setDataCsv] = useState([]);
  const [searchContentMessageDynamic, setSearchContentMessageDynamic] =
    React.useState("");

  useEffect(() => {
    getServices();
    getTemplates();
  }, []);

  const addValueGenerica = async (data, callback) => {
    let errores = await validateInputs([
      { value: proveedor, name: "proveedor", err: { empty: true } },
      {
        value: template && template.value ? template.value : "",
        name: "template",
        err: { empty: true },
      },
      { value: nombre, name: "nombre", err: { empty: true } },
      { value: producto, name: "producto", err: { empty: true } },
      { value: referencia, name: "referencia", err: { empty: true } },
      {
        value: periodicidad,
        name: "periodicidad",
        err: { empty: true, numeric: true },
      },
      { value: idScen, name: "idScen", err: { empty: true } },
      { value: archivo, name: "archivo", err: { empty: true } },
      { value: archivo2, name: "archivo2", err: { empty: true } },
      { value: archivo3, name: "archivo3", err: { empty: true } },
      { value: archivo4, name: "archivo4", err: { empty: true } },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario("valores/crear", data, "post", (error, estado, resp) => {
        if (estado === 200) {
          callback(false);
          setNombre("");
          setDescripcion("");
          setReferencia("");
          setProducto("");
          setTyc("");
          setPoliticas("");
          setTipoCategoria(null);
          setTipoCategoriaSub(null);
          getServices(search);
          setArchivo("");
          setArchivo2("");
          setArchivo3("");
          setArchivo4("");
          setArchivo5("");
          setArchivo6("");
          setArchivo7("");
          setArchivo9("");
          setTitulo("");
          setQuestions("");
          setSubscribe("");
          setCampana("");
          setUrl("");
          setClick("");
          setTwitter("");
          setFacebook("");
          setInstagram("");
          setPeriodicidad("");
          setIdScen("");
          setIdGoogle("");
          setCountryCode("");
          setCarrierCode("");
          setProveedor("");
          setTemplate(null);
          document.getElementById("banner_servicio").value = "";
          document.getElementById("footer_servicio").value = "";
          document.getElementById("banner_app_servicio").value = "";
          document.getElementById("menu_app_servicio").value = "";
          document.getElementById("fondo_servicio").value = "";
          document.getElementById("fondo_servicio_app").value = "";
          document.getElementById("logo_servicio").value = "";
          document.getElementById("video_suscripcion").value = "";
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          callback(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const updateValueGenerica = async (
    id,
    data,
    tipoUp = "update",
    callback = () => {}
  ) => {
    let errores = await validateInputs([
      { value: proveedor, name: "proveedor", err: { empty: true } },
      {
        value: template && template.value ? template.value : "",
        name: "template",
        err: { empty: true },
      },
      { value: nombre, name: "nombre", err: { empty: true } },
      { value: producto, name: "producto", err: { empty: true } },
      { value: referencia, name: "referencia", err: { empty: true } },
      {
        value: periodicidad,
        name: "periodicidad",
        err: { empty: true, numeric: true },
      },
      { value: idScen, name: "idScen", err: { empty: true } },
    ]);
    if (errores.length == 0 || tipoUp != "update_all") {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario(`valores/${id}`, data, "patch", (error, estado, resp) => {
        if (estado === 200) {
          callback(false);
          setNombre("");
          setDescripcion("");
          setReferencia("");
          setProducto("");
          setTyc("");
          setPoliticas("");
          setModalAdd(false);
          setTipo("add");
          setArchivo("");
          setArchivo2("");
          setArchivo3("");
          setArchivo4("");
          setArchivo5("");
          setArchivo6("");
          setArchivo7("");
          setArchivo9("");
          setTitulo("");
          setQuestions("");
          setSubscribe("");
          setCampana("");
          setUrl("");
          setClick("");
          setTwitter("");
          setFacebook("");
          setInstagram("");
          setPeriodicidad("");
          setIdScen("");
          setIdGoogle("");
          setCountryCode("");
          setCarrierCode("");
          setProveedor("");
          setTemplate(null);
          getServices(search);
          document.getElementById("banner_servicio").value = "";
          document.getElementById("footer_servicio").value = "";
          document.getElementById("banner_app_servicio").value = "";
          document.getElementById("menu_app_servicio").value = "";
          document.getElementById("fondo_servicio").value = "";
          document.getElementById("fondo_servicio_app").value = "";
          document.getElementById("logo_servicio").value = "";
          document.getElementById("video_suscripcion").value = "";
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          callback(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const updateValueGenericaNormal = async (id, data, callback = () => {}) => {
    callback(true);
    consulta(`valores/${id}`, data, "patch", (error, estado, resp) => {
      if (estado === 200) {
        callback(false);
        setModalAdd(false);
        setTipo("add");
        Toast.fire({ icon: "success", title: resp.titulo });
      } else {
        callback(false);
        Toast.fire({
          icon: "info",
          title: resp.titulo ? resp.titulo : mostrarError(resp),
        });
      }
    });
  };

  const addValueContent = async (data, callback) => {
    let e = [
      {
        value: tipoCategoria && tipoCategoria.value ? tipoCategoria.value : "",
        name: "tipoCategoria",
        err: { empty: true },
      },
      {
        value: fechaPublicacion,
        name: "fechaPublicacion",
        err: { empty: true },
      },
      { value: nombre, name: "nombre", err: { empty: true } },
    ];
    let type = typeFile("contenido_servicio", archivo);

    if (type != "image" && type != "")
      e.push({ value: archivo2, name: "archivo2", err: { empty: true } });
    let errores = await validateInputs(e);

    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario("contenido/crear", data, "post", (error, estado, resp) => {
        if (estado == 200) {
          callback(false);
          setNombre("");
          setDescripcion("");
          setArchivo("");
          setArchivo2("");
          setFechaPublicacion(new Date());
          setCategoria(true);
          getContentService(dataSele.id);
          document.getElementById("contenido_servicio").value = "";
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          callback(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
    return;
  };

  const updateMessage = async (id, data, callback) => {
    let errores = await validateInputs([
      { value: nombre, name: "content", err: { empty: true } },
      {
        value: descripcion,
        name: "short_code",
        err: { empty: true, numeric: true },
      },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      consulta(`mensajes/${id}`, data, "patch", (error, estado, resp) => {
        if (estado === 200) {
          callback(false);
          setModalAddmessage(false);
          getMessages(dataSele);
          setTipo("add");
          setNombre("");
          setDescripcion("");
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          callback(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const updateMessageDynamic = async (id, data, callback) => {
    let errores = await validateInputs([
      { value: nombre, name: "content", err: { empty: true } },
      { value: descripcion, name: "start_date", err: { empty: true } },
      { value: producto, name: "end_date", err: { empty: true } },
    ]);
    if (errores.length == 0) {
      let f1 = moment(descripcion).format();
      let f2 = moment(producto).format();
      if (f1 > f2) {
        Toast.fire({
          icon: "info",
          title: "la fecha de Inicio no puede ser mayor que la Final.",
        });
        return;
      }
      setErrores([]);
      callback(true);
      consulta(
        `mensajes/dynamic/${id}`,
        data,
        "patch",
        (error, estado, resp) => {
          if (estado === 200) {
            callback(false);
            setModalAddmessageDynamic(false);
            getMessagesDynamic(dataMessageDynamic[4]);
            setTipo("add");
            setNombre("");
            setDescripcion("");
            setProducto("");
            Toast.fire({ icon: "success", title: resp.titulo });
          } else {
            callback(false);
            Toast.fire({
              icon: "info",
              title: resp.titulo ? resp.titulo : mostrarError(resp),
            });
          }
        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const getMessages = (dataSele) => {
    let product_id = dataSele ? dataSele.valorc : "";
    setWaitMessage(true);
    consulta(`mensajes/${product_id}`, null, null, (error, estado, resp) => {
      if (estado == 200) {
        setMessages(resp.data);
        setDetalle(false);
      } else {
        setMessages([]);
        Toast.fire({ icon: "info", title: resp.titulo });
      }
      setWaitMessage(false);
    });
  };

  const getMessagesDynamic = (id, callback = () => {}, search = "") => {
    setWaitMessage(true);
    consulta(
      `mensajes/dynamic/${id}?search=${search}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado == 200) {
          setMessagesDynamic(resp.data);
        } else {
          setMessagesDynamic([]);
          Toast.fire({ icon: "info", title: resp.titulo });
        }
        callback();
        setWaitMessage(false);
      }
    );
  };

  const deleteMessage = async (id) => {
    setWaitMessage(true);
    consulta(`mensajes/${id}`, null, "delete", (error, estado, resp) => {
      if (estado === 200) {
        getMessages(dataSele);
        Toast.fire({ icon: "success", title: resp.titulo });
      } else {
        Toast.fire({
          icon: "info",
          title: resp.titulo ? resp.titulo : mostrarError(resp),
        });
      }
      setWaitMessage(false);
    });
  };

  const deleteMessageDynamic = async (id, callback = () => {}) => {
    setWaitMessage(true);
    consulta(
      `mensajes/dynamic/${id}`,
      null,
      "delete",
      (error, estado, resp) => {
        if (estado === 200) {
          callback();
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
        setWaitMessage(false);
      }
    );
  };

  const addMessage = async (data, callback) => {
    let errores = await validateInputs([
      { value: nombre, name: "content", err: { empty: true } },
      {
        value: descripcion,
        name: "short_code",
        err: { empty: true, numeric: true },
      },
    ]);
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      consulta(`mensajes_pos/add`, data, "patch", (error, estado, resp) => {
        if (estado === 200) {
          callback(false);
          setModalAddmessage(false);
          getMessages(dataSele);
          setTipo("add");
          setNombre("");
          setDescripcion("");
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          callback(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const addMessageDynamic = async (data, callback) => {
    let errores = await validateInputs([
      { value: nombre, name: "content", err: { empty: true } },
      { value: descripcion, name: "start_date", err: { empty: true } },
      { value: producto, name: "end_date", err: { empty: true } },
    ]);

    if (errores.length == 0) {
      let f1 = moment(descripcion).format();
      let f2 = moment(producto).format();
      if (f1 > f2) {
        Toast.fire({
          icon: "info",
          title: "la fecha de Inicio no puede ser mayor que la Final.",
        });
        return;
      }
      setErrores([]);
      callback(true);
      consulta(
        `mensajes_pos/dynamic/add`,
        data,
        "patch",
        (error, estado, resp) => {
          if (estado === 200) {
            callback(false);
            setModalAddmessageDynamic(false);
            getMessagesDynamic(data.uId);
            setTipo("add");
            setNombre("");
            setDescripcion("");
            setProducto("");
            Toast.fire({ icon: "success", title: resp.titulo });
          } else {
            callback(false);
            Toast.fire({
              icon: "info",
              title: resp.titulo ? resp.titulo : mostrarError(resp),
            });
          }
        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const validateFulldata = async (data) => {
    setWaitMessage(true);
    setDataCsv([]);
    setErrorsCsv([]);
    let resp = [];
    let errores = [];
    await data.map((e, i) => {
      let { end_date, start_date, content } = e;
      if (end_date && start_date && content) {
        let isEndDate = isNaN(end_date)
          ? moment(end_date).format("MMMM Do YYYY, h:mm:ss a")
          : "Invalid date";
        let isStartDate = isNaN(start_date)
          ? moment(start_date).format("MMMM Do YYYY, h:mm:ss a")
          : "Invalid date";
        if (isStartDate != "Invalid date" && isEndDate != "Invalid date") {
          e.warning = content.length > 320;
          e.description = e.warning ? "Contenido cortado." : "";
          let c = e.warning ? content.slice(0, 320) : content;
          e.content = c;
          resp.push(e);
        } else {
          errores.push({ row: i, description: "Revisar formato de fechas." });
        }
      } else {
        errores.push({
          row: i,
          description: "Todos los campos son obligatorios.",
        });
      }
    });

    if (resp.length == 0)
      Toast.fire({
        icon: "info",
        title: "Verifique el archivo, la información no es valida",
      });
    else if (resp.length > 0 && errores.length == 0)
      Toast.fire({ icon: "success", title: "Toda la información fue cargada" });
    else if (resp.length > 0 && errores.length > 0)
      Toast.fire({
        icon: "info",
        title: "Información cargada con errores, verifique por favor.",
      });

    setDataCsv(resp);
    setErrorsCsv(errores);
    setWaitMessage(false);
  };

  const addMessageDynamicFullData = async (data, callback) => {
    let errores = [];
    if (errores.length == 0) {
      setErrores([]);
      callback(true);
      consulta(
        `mensajes_pos/dynamic/add/full`,
        data,
        "patch",
        (error, estado, resp) => {
          if (estado === 200) {
            callback(false);
            document.getElementById("csv_masivo").value = "";
            // modalArchivoCsv(false);
            setDataCsv([]);
            setArchivo("");
            setErrorsCsv([]);
            getMessagesDynamic(data.uId);
            Toast.fire({ icon: "success", title: resp.titulo });
          } else {
            callback(false);
            Toast.fire({
              icon: "info",
              title: resp.titulo ? resp.titulo : mostrarError(resp),
            });
          }
        }
      );
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const getServices = (search = "") => {
    setWait("service");
    consulta(
      `servicios?search=${search}`,
      null,
      null,
      (error, estado, resp) => {
        setWait("");
        setData(resp);
      }
    );
  };

  const getContentService = (servicio = 1, searchContent = "") => {
    setWait("content");
    consulta(
      `servicio/${servicio}/contenido?search=${searchContent}`,
      null,
      null,
      (error, estado, resp) => {
        setWait("");
        setDataContent(resp);
      }
    );
  };

  const getCategorys = (service) => {
    setTipoCategoria(null);
    consulta(
      `valores/permisos?secundario=${service}&principal__generica=2`,
      null,
      null,
      (error, estado, resp) => {
        let data = transfornDataSeletPermission(resp);
        setDataCategory(data);
      }
    );
  };

  const getSubCategorys = (category) => {
    setTipoCategoriaSub(null);
    consulta(
      `valores/permisos?principal=${category}&principal__generica=2&secundario__generica=2`,
      null,
      null,
      (error, estado, resp) => {
        let data = transfornDataSeletPermissionSecundario(resp);
        setDataCategorySub(data);
      }
    );
  };

  const getTemplates = (search = "") => {
    setTemplate(null);
    consulta(
      `generica/${6}/valores?search=${search}`,
      null,
      null,
      (error, estado, resp) => {
        let data = transfornDataSelet(resp);
        setTemplates(data);
      }
    );
  };

  const updateContent = async (
    id,
    data,
    tipoUp = "update",
    callback = () => {}
  ) => {
    let e = [
      {
        value: tipoCategoria && tipoCategoria.value ? tipoCategoria.value : "",
        name: "tipoCategoria",
        err: { empty: true },
      },
      {
        value: fechaPublicacion,
        name: "fechaPublicacion",
        err: { empty: true },
      },
      { value: nombre, name: "nombre", err: { empty: true } },
    ];
    let type = typeFile("contenido_servicio", archivo);
    if (type != "image" && type != "")
      e.push({ value: archivo2, name: "archivo2", err: { empty: true } });
    let errores = await validateInputs(e);

    if (errores.length == 0 || tipoUp != "update_all") {
      setErrores([]);
      callback(true);
      data = await crear_form_data(data);
      formulario(`contenido/${id}`, data, "patch", (error, estado, resp) => {
        if (estado === 200) {
          if (!categoria)
            consulta(
              `contenido/${id}`,
              { tipo: null },
              "patch",
              (error, estado, resp) => estado
            );
          callback(false);
          getContentService(dataSele.id);
          setNombre("");
          setDescripcion("");
          setArchivo2("");
          setArchivo("");
          setFechaPublicacion(new Date());
          setCategoria(true);
          setModalAddContent(false);
          setTipoCategoria(null);
          setTipoCategoriaSub(null);
          setTipo("add");
          Toast.fire({ icon: "success", title: resp.titulo });
        } else {
          callback(false);
          Toast.fire({
            icon: "info",
            title: resp.titulo ? resp.titulo : mostrarError(resp),
          });
        }
      });
    } else {
      setErrores([]);
      setErrores(errores);
    }
  };

  const handleFileUpload = (event) => {
    setWaitMessage(true);
    const target = event.target;
    const name = target.name;
    let hojas = [];
    if (name === "archivo" && target.files[0]) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });
        if (hojas.length > 0) validateFulldata(hojas[0].data);
        else {
          Toast.fire({ icon: "info", title: "Archivo sin información" });
          setWaitMessage(false);
        }
      };
    } else {
      Toast.fire({ icon: "info", title: "Debe seleccionar un archivo" });
      setWaitMessage(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div ref={mainPanel}>
        <div>
          <Navbar
            text={currentUrl}
            callbackSearch={(data) => {
              setSearch(data);
              getServices(data);
            }}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              {wait == "service" && <WaitData />}
              {
                <div>
                  {data.length === 0 && wait == "" && <Empty />}
                  <GridContainer
                    style={{ display: wait == "service" && "none" }}
                  >
                    <ShowData
                      data={data}
                      setDataSele={setDataSele}
                      setModalDetail={setModalDetail}
                      updateValueGenerica={updateValueGenerica}
                      setModalAdd={setModalAdd}
                      setNombre={setNombre}
                      setDescripcion={setDescripcion}
                      setTitulo={setTitulo}
                      setProducto={setProducto}
                      setReferencia={setReferencia}
                      setTyc={setTyc}
                      setPoliticas={setPoliticas}
                      setTipo={setTipo}
                      setModalDetailContent={setModalDetailContent}
                      getContentService={getContentService}
                      getCategorys={getCategorys}
                      setQuestions={setQuestions}
                      setSubscribe={setSubscribe}
                      setCampana={setCampana}
                      setUrl={setUrl}
                      setClick={setClick}
                      setTwitter={setTwitter}
                      setInstagram={setInstagram}
                      setFacebook={setFacebook}
                      periodicidad={periodicidad}
                      setPeriodicidad={setPeriodicidad}
                      setIdScen={setIdScen}
                      setIdGoogle={setIdGoogle}
                      setCountryCode={setCountryCode}
                      setCarrierCode={setCarrierCode}
                      setTemplate={setTemplate}
                      setModalSettings={setModalSettings}
                      getMessages={getMessages}
                      setProveedor={setProveedor}
                    />
                  </GridContainer>
                  <ButtonFloating callback={() => setModalAdd(true)} />
                  <Dialog
                    open={modalAdd}
                    setOpen={(modal) => {
                      if (tipo == "update") {
                        setNombre("");
                        setDescripcion("");
                        setReferencia("");
                        setProducto("");
                        setTyc("");
                        setTitulo("");
                        setArchivo("");
                        setPoliticas("");
                        setArchivo2("");
                        setArchivo3("");
                        setArchivo4("");
                        setArchivo5("");
                        setArchivo6("");
                        setQuestions("");
                        setSubscribe("");
                        setCampana("");
                        setUrl("");
                        setClick("");
                        setTwitter("");
                        setFacebook("");
                        setInstagram("");
                        setPeriodicidad("");
                        setIdGoogle("");
                        setCountryCode("");
                        setCarrierCode("");
                      }
                      setTipo("add");
                      setErrores([]);
                      setModalAdd(modal);
                    }}
                    title={`${tipo == "add" ? "Nuevo" : "Modificar"} Servicio`}
                    subTitle="Complete los siguientes campos"
                    children={
                      <AddService
                        errores={errores}
                        descripcion={descripcion}
                        nombre={nombre}
                        setDescripcion={setDescripcion}
                        setNombre={setNombre}
                        producto={producto}
                        setProducto={setProducto}
                        referencia={referencia}
                        setReferencia={setReferencia}
                        setTyc={setTyc}
                        tyc={tyc}
                        setArchivo={setArchivo}
                        archivo={archivo}
                        setArchivo3={setArchivo3}
                        archivo3={archivo3}
                        setArchivo5={setArchivo5}
                        archivo5={archivo5}
                        archivo6={archivo6}
                        setArchivo4={setArchivo4}
                        archivo4={archivo4}
                        titulo={titulo}
                        setTitulo={setTitulo}
                        setArchivo2={setArchivo2}
                        setArchivo6={setArchivo6}
                        setPoliticas={setPoliticas}
                        politicas={politicas}
                        archivo2={archivo2}
                        setQuestions={setQuestions}
                        setSubscribe={setSubscribe}
                        setCampana={setCampana}
                        setUrl={setUrl}
                        setClick={setClick}
                        questions={questions}
                        subscribe={subscribe}
                        campana={campana}
                        url={url}
                        click={click}
                        twitter={twitter}
                        setTwitter={setTwitter}
                        Instagram={Instagram}
                        setInstagram={setInstagram}
                        facebook={facebook}
                        setFacebook={setFacebook}
                        periodicidad={periodicidad}
                        setPeriodicidad={setPeriodicidad}
                        setIdScen={setIdScen}
                        setIdGoogle={setIdGoogle}
                        setCountryCode={setCountryCode}
                        setCarrierCode={setCarrierCode}
                        idScen={idScen}
                        idGoogle={idGoogle}
                        countryCode={countryCode}
                        carrierCode={carrierCode}
                        tipo={tipo}
                        template={template}
                        templates={templates}
                        setTemplate={setTemplate}
                        setArchivo7={setArchivo7}
                        setArchivo9={setArchivo9}
                        setLinkPage={setLinkPage}
                        archivo7={archivo7}
                        archivo9={archivo9}
                        linkPage={linkPage}
                        setProveedor={setProveedor}
                        proveedor={proveedor}
                        callback={() =>
                          updateValueGenericaNormal(dataSele.id, {
                            archivo6: null,
                            archivo5: null,
                            archivo9: null,
                          })
                        }
                      />
                    }
                    callback={(handleClose, setWaitModal) => {
                      let banner =
                        document.getElementById("banner_servicio").files[0];
                      let footer =
                        document.getElementById("footer_servicio").files[0];
                      let banner_app = document.getElementById(
                        "banner_app_servicio"
                      ).files[0];
                      let menu_app =
                        document.getElementById("menu_app_servicio").files[0];
                      let fondo =
                        document.getElementById("fondo_servicio").files[0];
                      let fondo_app =
                        document.getElementById("fondo_servicio_app").files[0];
                      let video_suscripcion =
                        document.getElementById("video_suscripcion").files[0];
                      let logo_app =
                        document.getElementById("logo_servicio").files[0];
                      let valora = colorDinamic().color;
                      let valorb = parseInt(Math.random() * (10 - 0) + 0);
                      let valorc = producto;
                      let valord = referencia;
                      let valore = tyc;
                      let valorf = titulo;
                      let valorg = politicas;
                      let valori = questions;
                      let valoru = subscribe;
                      let valorv = campana;
                      let valorw = url;
                      let valorj = facebook;
                      let valork = twitter;
                      let valorl = Instagram;
                      let valorm = periodicidad;
                      let valorn = idScen;
                      let valorq = idGoogle;
                      let valorx = countryCode;
                      let valory = carrierCode;
                      let valorr = proveedor;
                      let valors = linkPage;
                      let valort = click;
                      let dataAdd = {
                        valorw,
                        valorv,
                        valort,
                        valors,
                        valorr,
                        valorq,
                        valoro:
                          template && template.value ? template.value : "",
                        valorn,
                        valorj,
                        valork,
                        valorl,
                        valorm,
                        generica: 1,
                        nombre,
                        descripcion,
                        valora,
                        valorf,
                        valorb,
                        valorc,
                        valord,
                        valore,
                        archivo: banner,
                        archivo2: footer,
                        archivo3: banner_app,
                        archivo4: menu_app,
                        valorg,
                        valori,
                        valoru,
                        valorx,
                        valory,
                      };
                      let dataUpd = {
                        valorw,
                        valorv,
                        valort,
                        valors,
                        valorr,
                        valorq,
                        valoro:
                          template && template.value ? template.value : "",
                        valorn,
                        valorj,
                        valork,
                        valorl,
                        valorm,
                        nombre,
                        descripcion,
                        valorc,
                        valord,
                        valore,
                        valorf,
                        valorg,
                        valori,
                        valoru,
                        valorx,
                        valory,
                      };
                      if (archivo) dataUpd.archivo = banner;
                      if (archivo2) dataUpd.archivo2 = footer;
                      if (archivo3) dataUpd.archivo3 = banner_app;
                      if (archivo4) dataUpd.archivo4 = menu_app;
                      if (archivo5) dataUpd.archivo5 = fondo;
                      if (archivo5) dataAdd.archivo5 = fondo;
                      if (archivo6) dataUpd.archivo6 = fondo_app;
                      if (archivo6) dataAdd.archivo6 = fondo_app;
                      if (archivo7) dataUpd.archivo7 = logo_app;
                      if (archivo7) dataAdd.archivo7 = logo_app;
                      if (archivo9) dataAdd.archivo9 = video_suscripcion;
                      if (archivo9) dataUpd.archivo9 = video_suscripcion;
                      if (tipo == "add")
                        addValueGenerica(dataAdd, setWaitModal);
                      else
                        updateValueGenerica(
                          dataSele.id,
                          dataUpd,
                          "update_all",
                          setWaitModal
                        );
                      return false;
                    }}
                  />

                  <Dialog
                    nameBtn1=""
                    nameBtn2="CERRAR"
                    open={modalDetail}
                    setOpen={setModalDetail}
                    title={`Detalle Servicio`}
                    subTitle={""}
                    children={
                      dataSele ? <DetailService data={dataSele} /> : <p></p>
                    }
                    // tasks={ <OpenServiceTasks /> }
                  />

                  <Dialog
                    activeSearch={messageSelect == "subscription" && detalle}
                    setSearch={(buscar) =>
                      setSearchContentMessageDynamic(buscar)
                    }
                    callbackSearch={
                      messageSelect == "subscription" && detalle
                        ? () => {
                            let existe = messages.find(
                              (e) => e[5] == messageSelect
                            );
                            getMessagesDynamic(
                              existe[0],
                              () => true,
                              searchContentMessageDynamic
                            );
                          }
                        : () => true
                    }
                    width="md"
                    nameBtn1=""
                    nameBtn2="CERRAR"
                    open={modalSettings}
                    setOpen={setModalSettings}
                    title={`Mensajes`}
                    subTitle={dataSele ? dataSele.nombre : ""}
                    children={
                      <ContentMessages
                        setProducto={setProducto}
                        setModalAddmessageDynamic={setModalAddmessageDynamic}
                        deleteMessageDynamic={deleteMessageDynamic}
                        messagesDynamic={messagesDynamic}
                        getMessagesDynamic={getMessagesDynamic}
                        deleteMessage={deleteMessage}
                        setTipo={setTipo}
                        setDescripcion={setDescripcion}
                        setNombre={setNombre}
                        messages={messages}
                        messageSelect={messageSelect}
                        waitMessage={waitMessage}
                        setModalAddmessage={setModalAddmessage}
                        setDataMessageDynamic={setDataMessageDynamic}
                        detalle={detalle}
                        setDetalle={setDetalle}
                        setModalArchivoCsv={setModalArchivoCsv}
                      />
                    }
                    tasks={
                      <TabsMessages
                        setMessageSelect={setMessageSelect}
                        setDetalle={setDetalle}
                      />
                    }
                  />

                  <Dialog
                    width="md"
                    actions={false}
                    open={modalDetailContent}
                    setOpen={setModalDetailContent}
                    title={`${dataSele && dataSele.nombre}`}
                    subTitle={`Mostrando ${
                      wait ? 0 : dataContent.length
                    } registros`}
                    activeSearch={true}
                    search={searchContent}
                    setSearch={setSearchContent}
                    callbackSearch={() =>
                      getContentService(dataSele.id, searchContent)
                    }
                    children={
                      <OpenServiceContent
                        dataContent={dataContent}
                        setModalAddContent={setModalAddContent}
                        updateContent={updateContent}
                        setDataContentSele={setDataContentSele}
                        setTipo={setTipo}
                        setDescripcion={setDescripcion}
                        setNombre={setNombre}
                        setCategoria={setCategoria}
                        setFechaPublicacion={setFechaPublicacion}
                        setTipoCategoria={setTipoCategoria}
                        setTipoCategoriaSub={setTipoCategoriaSub}
                        wait={wait}
                      />
                    }
                    // tasks={ <OpenServiceTasks /> }
                  />
                  <Dialog
                    nameBtn1={dataCsv.length > 0 ? "Enviar" : ""}
                    nameBtn2="Cerrar"
                    width="md"
                    open={modalArchivoCsv}
                    setOpen={setModalArchivoCsv}
                    title={`Agregar Mensajes`}
                    subTitle={`Cargar archivo csv`}
                    children={
                      <div>
                        <AddCsv
                          reset={() => {
                            document.getElementById("csv_masivo").value = "";
                            setArchivo("");
                            setDataCsv([]);
                            setErrorsCsv([]);
                          }}
                          handleFileUpload={handleFileUpload}
                          errores={errores}
                          archivo={archivo}
                          setArchivo={setArchivo}
                          dataCsv={dataCsv}
                          errorsCsv={errorsCsv}
                          waitMessage={waitMessage}
                        />
                      </div>
                    }
                    callback={(handleClose, setWaitModal) => {
                      if (dataCsv.length > 0) {
                        let existe = messages.find(
                          (e) => e[5] == messageSelect
                        );
                        addMessageDynamicFullData(
                          { data: dataCsv, uId: existe[0] },
                          setWaitModal
                        );
                      }
                    }}
                  />

                  <Dialog
                    width="xs"
                    nameBtn1="ACEPTAR"
                    nameBtn2="CANCELAR"
                    open={modalAddMessage}
                    setOpen={(show) => {
                      setModalAddmessage(show);
                      if (tipo != "add") {
                        setNombre("");
                        setDescripcion("");
                      }
                      setTipo("add");
                      setErrores([]);
                    }}
                    title={`${tipo == "add" ? "Agregar" : "Modificar"} Mensaje`}
                    children={
                      <AddMesagge
                        errores={errores}
                        descripcion={descripcion}
                        nombre={nombre}
                        setDescripcion={setDescripcion}
                        setNombre={setNombre}
                      />
                    }
                    subTitle={`Complete los siguientes campos`}
                    callback={(handleClose, setWaitModal) => {
                      let dataAdd = {
                        content: nombre,
                        short_code: descripcion,
                        product_id: dataSele.valorc,
                        keyword: messageSelect,
                      };
                      let dataUpd = {
                        content: nombre,
                        short_code: descripcion,
                      };
                      let existe = messages.find((e) => e[5] == messageSelect);
                      if (existe && tipo == "update")
                        updateMessage(existe[0], dataUpd, setWaitModal);
                      if (tipo == "add") addMessage(dataAdd, setWaitModal);

                      return false;
                    }}
                  />

                  <Dialog
                    width="xs"
                    nameBtn1="ACEPTAR"
                    nameBtn2="CANCELAR"
                    open={modalAddMessageDynamic}
                    setOpen={(show) => {
                      setProducto(`${moment().format("YYYY-MM-DD")}T23:59`);
                      setDescripcion(`${moment().format("YYYY-MM-DD")}T00:00`);
                      setModalAddmessageDynamic(show);
                      if (tipo != "add") {
                        setNombre();
                        setDescripcion("");
                        setProducto("");
                      }
                      setTipo("add");
                      setErrores([]);
                    }}
                    title={`${tipo == "add" ? "Agregar" : "Modificar"} Mensaje`}
                    children={
                      <AddMesaggeDynamic
                        errores={errores}
                        descripcion={descripcion}
                        nombre={nombre}
                        setDescripcion={setDescripcion}
                        setNombre={setNombre}
                        setProducto={setProducto}
                        producto={producto}
                      />
                    }
                    subTitle={`Complete los siguientes campos`}
                    callback={(handleClose, setWaitModal) => {
                      let existe = messages.find((e) => e[5] == messageSelect);
                      let dataAdd = {
                        content: nombre,
                        start_date: descripcion,
                        end_date: producto,
                        uId: existe[0],
                      };
                      let dataUpd = {
                        content: nombre,
                        start_date: descripcion,
                        end_date: producto,
                      };
                      if (tipo == "update")
                        updateMessageDynamic(
                          dataMessageDynamic[0],
                          dataUpd,
                          setWaitModal
                        );
                      else if (tipo == "add")
                        addMessageDynamic(dataAdd, setWaitModal);
                      return false;
                    }}
                  />

                  <Dialog
                    nameBtn1="ACEPTAR"
                    nameBtn2="CANCELAR"
                    open={modalAddContent}
                    setOpen={(show) => {
                      setModalAddContent(show);
                      if (tipo != "add") {
                        setNombre("");
                        setDescripcion("");
                        setArchivo("");
                        document.getElementById("contenido_servicio").value =
                          "";
                      }
                      setTipo("add");
                      setErrores([]);
                    }}
                    title={`${
                      tipo == "add" ? "Agregar" : "Modificar"
                    } Contenido`}
                    children={
                      <AddContentService
                        errores={errores}
                        descripcion={descripcion}
                        nombre={nombre}
                        setDescripcion={setDescripcion}
                        setNombre={setNombre}
                        setArchivo={setArchivo}
                        archivo={archivo}
                        setArchivo2={setArchivo2}
                        archivo2={archivo2}
                        categoria={categoria}
                        setCategoria={setCategoria}
                        fechaPublicacion={fechaPublicacion}
                        setFechaPublicacion={setFechaPublicacion}
                        tipoCategoria={tipoCategoria}
                        setTipoCategoria={setTipoCategoria}
                        tipoCategoriaSub={tipoCategoriaSub}
                        setTipoCategoriaSub={setTipoCategoriaSub}
                        dataCategory={dataCategory}
                        dataCategorySub={dataCategorySub}
                        getSubCategorys={getSubCategorys}
                      />
                    }
                    subTitle={`Complete los siguientes campos`}
                    callback={(handleClose, setWaitModal) => {
                      let file =
                        document.getElementById("contenido_servicio").files[0];
                      let file2 = document.getElementById(
                        "contenido_servicio_preview"
                      )
                        ? document.getElementById("contenido_servicio_preview")
                            .files[0]
                        : "";
                      let tipo_archivo = typeFile(
                        "contenido_servicio",
                        archivo
                      );
                      let fecha_publicacion =
                        moment(fechaPublicacion).format("YYYY-MM-DD");
                      let dataAdd = {
                        servicio: dataSele.id,
                        nombre,
                        descripcion,
                        fecha_publicacion,
                        categoria: categoria ? 1 : 0,
                      };
                      let dataUpd = {
                        nombre,
                        descripcion,
                        fecha_publicacion,
                        categoria: categoria ? 1 : 0,
                      };
                      if (archivo) {
                        dataUpd.archivo = file;
                        dataAdd.archivo = file;
                        dataUpd.tipo_archivo = tipo_archivo;
                        dataAdd.tipo_archivo = tipo_archivo;

                        if (archivo2 && tipo_archivo != "image")
                          dataAdd.archivo2 = file2;
                        else dataAdd.archivo2 = file;

                        if (tipo_archivo == "image") dataUpd.archivo2 = file;
                        else if (archivo2) dataUpd.archivo2 = file2;
                      }
                      if (categoria && tipoCategoria) {
                        dataAdd.tipo = tipoCategoria.value;
                        dataUpd.tipo = tipoCategoria.value;
                      }
                      if (categoria && tipoCategoriaSub) {
                        dataAdd.sub_tipo = tipoCategoriaSub.value;
                        dataUpd.sub_tipo = tipoCategoriaSub.value;
                      }

                      if (tipo == "add") addValueContent(dataAdd, setWaitModal);
                      else
                        updateContent(
                          dataContentSele.id,
                          dataUpd,
                          "update_all",
                          setWaitModal
                        );
                      return false;
                    }}
                  />
                </div>
              }
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
