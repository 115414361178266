import React, { useState } from "react";
import {
  Dialog,
  InputBase,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "../CustomButtons/Button.js";
import CardHeader from "../Card/CardHeader.js";

// core components
import styles from "../../assets/jss/material-dashboard-react/components/dialogStyle.js";
import loading from "../../assets/img/loading.gif";
import stylesAdmin from "../../assets/jss/material-dashboard-react/layouts/adminStyle.js";

const useStyles = makeStyles(styles);
const useStylesAdmin = makeStyles(stylesAdmin);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WaitData({ text = "Cargando...", margin = "10% 0 0 0" }) {
  const classes = useStylesAdmin();
  return (
    <div className={classes.contentWait} style={{ margin }}>
      <img src={loading} alt="" className={classes.contentWaitImg} />
      <div className={classes.contentWaitText}>{text}</div>
    </div>
  );
}

function Search({ callback, setSearch, search }) {
  const classes = useStylesAdmin();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        value={search}
        onKeyDown={(e) => e.key === "Enter" && callback()}
        onChange={({ target: { value } }) => setSearch(value)}
        placeholder="Buscar..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
}

export default function AlertDialogSlide({
  activeSearch = false,
  setSearch,
  search,
  callbackSearch,
  width = "sm",
  fullWidth = true,
  open,
  setOpen,
  title,
  subTitle = "",
  nameBtn1 = "Aceptar",
  nameBtn2 = "Cancelar",
  children,
  color = "primary",
  callback,
  tasks = null,
  actions = true,
}) {
  const classes = useStyles();
  const [wait, setWait] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={width}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ overflow: "hidden" }}
    >
      <DialogTitle id="alert-dialog-slide-title">
        <CardHeader color={color}>
          <h4 className={classes.cardTitleWhite}>{title}</h4>
          {subTitle && <p className={classes.cardCategoryWhite}>{subTitle}</p>}
          {tasks && tasks}
          {activeSearch && (
            <Search
              setSearch={setSearch}
              search={search}
              callback={callbackSearch}
            />
          )}
        </CardHeader>
      </DialogTitle>
      <DialogContent className="scroll">
        <DialogContentText id="alert-dialog-slide-description">
          {wait ? <WaitData /> : children}
        </DialogContentText>
      </DialogContent>
      {actions && (
        <DialogActions>
          <Button onClick={handleClose}>{nameBtn2}</Button>
          {nameBtn1 && (
            <Button
              disabled={wait}
              color={color}
              onClick={() => callback(handleClose, setWait)}
            >
              {" "}
              {nameBtn1}{" "}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
